import { useRef } from "react";

import { useExhaustiveEffect } from "ii-ui-kit";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { BUILD_NUMBER, IS_TESTING_STAGING } from "const";
import { Snackbar } from "core/components/snackbar/snackbar";
import { AppRoutes } from "core/enums/routes";
import STEP_KEYS from "core/enums/steps";
import AppRouter from "core/router/app-router";
import { appSelectors } from "core/store/reducers/app-reducer";
import { useSnackbar } from "core/store/reducers/app-reducer/hooks";
import { authSelectors } from "core/store/reducers/auth-reducer";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    buildNumber: string | undefined;
  }
}

const STEP_KEYS_TO_ROUTES_MAP: Record<STEP_KEYS, AppRoutes> = {
  [STEP_KEYS.PRODUCT_SELECT]: AppRoutes.ProductSelect,
  [STEP_KEYS.PERSONAL_DATA]: AppRoutes.PersonalData,
  [STEP_KEYS.DOCUMENTS]: AppRoutes.Documents,
  [STEP_KEYS.FUND_SELECT]: AppRoutes.FundSelect,
  [STEP_KEYS.PAYMENT]: AppRoutes.Payment,
} as const;

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const agentEmployeeId = useSelector(authSelectors.selectAgentEmployeeID);
  const currentStep = useSelector(appSelectors.selectCurrentStep);
  const snackbarRef = useRef<HTMLDivElement>(null);
  const {
    actions: { clearSnackbarAlert },
    snackbarState: {
      title,
      text,
      type,
      isOpen,
      position,
      buttonText,
      onButtonClick,
    },
  } = useSnackbar();

  useExhaustiveEffect(() => {
    if (IS_TESTING_STAGING) {
      window.buildNumber = BUILD_NUMBER;
    }

    if (
      [
        AppRoutes.ClientDocumentsSign,
        AppRoutes.ClientDocumentsSignSuccess,
      ].includes(pathname as AppRoutes)
    ) {
      return;
    }

    if (!agentEmployeeId) {
      navigate(AppRoutes.Auth);
      return;
    }

    navigate(STEP_KEYS_TO_ROUTES_MAP[currentStep]);
  }, [agentEmployeeId]);

  return (
    <div className="App">
      <div ref={snackbarRef}>
        <Snackbar
          title={title}
          type={type}
          text={text}
          isOpen={isOpen}
          position={position}
          portalContainer={snackbarRef}
          onClose={clearSnackbarAlert}
          buttonText={buttonText}
          onButtonClick={onButtonClick}
        />
      </div>
      <AppRouter />
    </div>
  );
}

export default App;
