import React from "react";

import { Button, Modal } from "ii-ui-kit";

import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";
import { ProductType } from "core/types/entities/product-type";

import "./index.scss";

type ProductFormProps = {
  onProductSelect: (productType: ProductType) => void;
};

const ProductForm = ({ onProductSelect }: ProductFormProps) => {
  const dispatch = useAppDispatch();

  const handleClickClose = () => {
    dispatch(appActions.clearStore());
  };
  return (
    <Modal
      className="product-form"
      header={<div className="product-form__header">Выбор типа операции</div>}
      shown
      swipeable
      closable
      onClose={handleClickClose}
    >
      <div className="product-form__body">
        <Button
          onClick={onProductSelect.bind(null, ProductType.PIF)}
          color="secondary"
          disabled
        >
          Покупка ПИФ
        </Button>
        <Button
          onClick={onProductSelect.bind(null, ProductType.DU)}
          color="secondary"
        >
          Покупка ДУ
        </Button>
      </div>
    </Modal>
  );
};

export default ProductForm;
