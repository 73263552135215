export enum ClientCheckStatus {
  Pending,
  Success,
  Failed,
}

/** Тип изменяющихся полей на странице */
export type IPageContent = {
  /** Заголовок */
  title: string;
  /** Подпись поля с выбором продукта */
  productLabel: string;
  /** Подпись поля с вводом суммы */
  amountLabel: string;
};
