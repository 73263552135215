import { DATE_FORMAT } from "core/enums/date-format";
import { ProductType } from "core/types/entities/product-type";

import { formatDate } from "../date-formatters";

type IParams = {
  productType: ProductType;
  fullName: string;
  paymentInfo: {
    qrName: string;
    orderDate: string;
    orderNumber: string;
    fund: string;
  };
};

type IReturnPurpose = {
  sbpPurpose: string;
  requisitesPurpose: string;
};

/** Сгенерировать цель платежа */
export const generatePaymentPurpose = ({
  productType,
  fullName,
  paymentInfo: { qrName, orderDate, orderNumber, fund },
}: IParams): IReturnPurpose => {
  const formattedDate = formatDate(orderDate, DATE_FORMAT.FULL_DATE);

  switch (productType) {
    case ProductType.PIF:
      return {
        sbpPurpose: `Покупка паёв ${qrName} от ${formattedDate} N ${orderNumber}, ${fullName}, Без НДС`,
        requisitesPurpose: `На приобретение инвестиционных паев ${fund} по заявке N ${orderNumber} от ${formattedDate} ${fullName}. НДС не облагается.`,
      };
    case ProductType.DU:
      return {
        sbpPurpose: `Передача денежных средств в доверительное управление, согласно Договору ДУ ценными бумагами ${orderNumber} от ${formattedDate}, Без НДС.`,
        requisitesPurpose: `Перевод в ДУ по стратегии ${fund} по договору ДУ №${orderNumber} от ${formattedDate}, ${fullName}. НДС не облагается.`,
      };
    case ProductType.IIS:
      return {
        sbpPurpose: `Передача денежных средств в доверительное управление, согласно Договору ДУ ценными бумагами ${orderNumber} от ${formattedDate}, Без НДС.`,
        requisitesPurpose: `Перевод на ИИС по стратегии ${fund} по договору ДУ №${orderNumber} от ${formattedDate}, ${fullName}. НДС не облагается.`,
      };
  }
};
