import React from "react";

import classNames from "classnames";

import { utils } from "./utils";

import "./steps.scss";

type StepItem = {
  key: string | number;
  title: string;
  isActive: boolean;
  isPassed: boolean;
};

type Props = {
  addClass?: string;
  items: StepItem[];
};

export const Steps: React.FC<Props> = ({ addClass, items }) => (
  <ul
    className={classNames({
      steps: true,
      [addClass as string]: Boolean(addClass),
    })}
  >
    {items.map(({ key, title, isActive, isPassed }) => (
      <li
        key={key}
        className={classNames("steps__item step-item", {
          "step-item--active": isActive,
          "step-item--passed": isPassed,
          "step-item--default": !isActive && !isPassed,
        })}
      >
        <div className="step-item__icon">
          {utils.renderIcon(
            isActive ? "active" : isPassed ? "passed" : "default"
          )}
        </div>
        <div className="step-item__text">{title}</div>
      </li>
    ))}
  </ul>
);
