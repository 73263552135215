import { FC } from "react";

import { Backdrop, Loader } from "ii-ui-kit";

import "./index.scss";

export type BackdropLoaderProps = {
  isLoading: boolean;
};

export const BackdropLoader: FC<BackdropLoaderProps> = ({ isLoading }) => {
  return (
    <Backdrop shown={isLoading}>
      <div className="app-loader">{isLoading && <Loader />}</div>
    </Backdrop>
  );
};
