import { useMemo } from "react";

import { usePDF } from "@react-pdf/renderer";
import { DownloadPanel, DropZone, Informer } from "ii-ui-kit";
import { UseFormReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";

import ErrorMessage from "core/components/error-message";
import { DATE_FORMAT } from "core/enums/date-format";
import { formatDate } from "core/utils/date-formatters";
import { getMeta } from "core/utils/form";
import { IPersonalDataForm } from "pages/personal-data/types";
import { agentsLandingApi } from "services/agents-landing-service";

import EdoDocument from "./edo-document";

type EdoSectionProps = {
  form: UseFormReturn<IPersonalDataForm>;
  watch: UseFormWatch<IPersonalDataForm>;
  setValue: UseFormSetValue<IPersonalDataForm>;
};

const EdoSection = ({ watch, setValue, form }: EdoSectionProps) => {
  const [uploadFileMutation, uploadFileMutationState] =
    agentsLandingApi.useUploadFileMutation();

  const uploadFile = async (file: File) => {
    if (!file) {
      setValue("personDocumentScan.2.documentGUID", "", {
        shouldValidate: true,
      });

      return;
    }

    await uploadFileMutation(file)
      .unwrap()
      .then(({ body }) => {
        setValue("personDocumentScan.2.documentGUID", body.guid, {
          shouldValidate: true,
        });
      })
      .catch(() => {
        form.setError("personDocumentScan.2.documentGUID", {
          message: "Ошибка загрузки. Попробуйте заново",
        });
      });
  };

  const [
    firstName,
    lastName,
    patronymic,
    birthDate,
    passport,
    address,
    email,
    phone,
  ] = watch([
    "firstName",
    "lastName",
    "patronymic",
    "birthDate",
    "passportSeriesAndNumber",
    "registerAddress",
    "email",
    "mobile",
  ]);

  const parsedBirthDate = useMemo(() => {
    try {
      return formatDate(birthDate, DATE_FORMAT.FULL_DATE);
    } catch (err) {
      return undefined;
    }
  }, [birthDate]);

  const [edoDocument] = usePDF({
    document: (
      <EdoDocument
        {...{
          firstName,
          lastName,
          patronymic,
          birthDate: parsedBirthDate,
          passport,
          email,
          phone,
          address: address.stringAddress,
        }}
      />
    ),
  });

  const edoDocumentError = getMeta(
    form,
    "personDocumentScan.2.documentGUID"
  ).errorText;

  return (
    <div className="personal-data__section">
      <div className="personal-data__subtitle">Электронный документооборот</div>
      <DownloadPanel
        text="Соглашение об электронном документообороте"
        href="/documents/edo.pdf"
      />
      <Informer
        isOpen
        type="info-without-shadow"
        isDisplayIcon
        text="Приложите скан заявления о присоединении к соглашению об электронном документообороте, подписанный клиентом"
      />
      <DownloadPanel
        newFileName="edo.pdf"
        text="Заявление о присоединении к соглашению об электронном документообороте"
        href={edoDocument.url || ""}
      />
      <div>
        <DropZone
          maxFiles={1}
          title="Приложите заявление"
          accept={[".png", ".jpeg", ".jpg", ".pdf", ".tiff"]}
          isLoading={uploadFileMutationState.isLoading}
          onChange={([file]) => uploadFile(file)}
        />
        {edoDocumentError && <ErrorMessage errorText={edoDocumentError} />}
      </div>
    </div>
  );
};

export default EdoSection;
