import { FC } from "react";

import QRCodeReact from "qrcode.react";

import logo from "assets/logo/ingosinvest-logo.svg";
import { getQrCodePaymentValue } from "core/utils/get-qr-code-value";
import { BankModel, ReceiverInfoModel } from "pages/payment-page/types";

import "./index.scss";

type QrCodeProps = {
  bank: BankModel;
  receiverInfo: ReceiverInfoModel;
  paymentPurpose: string;
  amountMoney: number;
  payerInfo: {
    lastName: string;
    firstName: string;
    patronymic: string;
  };
};

const imageSettings = {
  src: logo,
  height: 27,
  width: 27,
  excavate: true,
};

const QrCode: FC<QrCodeProps> = ({
  bank,
  receiverInfo,
  paymentPurpose,
  amountMoney,
  payerInfo,
}) => {
  const qrCodeValue = getQrCodePaymentValue({
    bankBik: bank.requisites.bik,
    bankName: bank.label,
    bankCorrAccount: bank.requisites.corrAccount,
    bankCheckingAccount: bank.requisites.checkingAccount,
    receiverInn: receiverInfo.inn,
    receiverKpp: receiverInfo.kpp,
    receiverName: receiverInfo.name,
    paymentPurpose,
    amountMoney,
    payerLastName: payerInfo.lastName,
    payerPatronymic: payerInfo.patronymic,
    payerFirstName: payerInfo.firstName,
  });

  return (
    <div className="qr-code">
      <div className="qr-code__note">
        Клиенту необходимо открыть мобильное приложение своего банка и считать
        QR-код
      </div>
      <div className="qr-code__code">
        <QRCodeReact
          value={qrCodeValue}
          renderAs="svg"
          size={260}
          includeMargin={false}
          level="L"
          imageSettings={imageSettings}
        />
      </div>
    </div>
  );
};

export default QrCode;
