import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";

import { Reducers } from "core/enums/reducers";
import { crmReducer } from "core/store/reducers/crm-reducer";
import { fundSelectReducer } from "core/store/reducers/fund-select-reducer/reducer";
import { paymentReducer } from "core/store/reducers/payment-reducer/reducer";
import { agentClientSignApi } from "services/agent-client-sign";
import { agentsLandingApi } from "services/agents-landing-service";
import { agentsApi } from "services/agents-service";
import { authApi } from "services/auth-service";
import { merchantApi } from "services/merchant-service";
import { productsApi } from "services/products-service";
import { suggestionsApi } from "services/suggestions-service";

import appReducer from "./reducers/app-reducer";
import { appStateTransform } from "./reducers/app-reducer/transforms";
import { authReducer } from "./reducers/auth-reducer";
import { personalDataReducer } from "./reducers/personal-data-reducer";

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [suggestionsApi.reducerPath]: suggestionsApi.reducer,
  [agentsLandingApi.reducerPath]: agentsLandingApi.reducer,
  [agentsApi.reducerPath]: agentsApi.reducer,
  [merchantApi.reducerPath]: merchantApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [agentClientSignApi.reducerPath]: agentClientSignApi.reducer,
  [Reducers.App]: appReducer,
  [Reducers.Auth]: authReducer,
  [Reducers.Crm]: crmReducer,
  [Reducers.Payment]: paymentReducer,
  [Reducers.PersonalData]: personalDataReducer,
  [Reducers.FundSelect]: fundSelectReducer,
});

const persistConfig = {
  key: "rootAgentMfOnboarding",
  storage,
  whitelist: [
    Reducers.App,
    Reducers.Auth,
    Reducers.Crm,
    Reducers.Payment,
    Reducers.PersonalData,
    Reducers.FundSelect,
  ],
  transforms: [appStateTransform],
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      agentsApi.middleware,
      suggestionsApi.middleware,
      agentsLandingApi.middleware,
      merchantApi.middleware,
      productsApi.middleware,
      agentClientSignApi.middleware,
    ]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
