import IBankAccount from "core/types/entities/bank";
import IClient from "core/types/entities/client";
import IContract from "core/types/entities/contract";
import ILead from "core/types/entities/lead";
import { Nullable } from "core/types/utils/nullable";

/** Тип онбординга */
export enum ONBOARDING_TYPE {
  /** Клиентский */
  CLIENT = 0,
  /** Агентский */
  AGENT = 1,
}

// Leads
export interface ILandingAgentsUpdateLeadRequest {
  leadDescription: ILead;
  client: IClient;
}

export interface ILandingAgentsUploadFileResponse {
  guid: string;
}

export interface ILandingAgentsGetINNRequest {
  lastName: string;
  firstName: string;
  middleName?: string;
  birthDate: string;
  passport: string;
}

export interface ILandingAgentsClientCheckResultQuery {
  CRMLeadId: string;
}

export interface ILandingAgentsClientCheckResultResponse {
  crmLeadId: string;
  crmClientId: string;
  clientFansyId: string;
  smewStatus: number;
  prismaStatus: number;
  isNew: true;
  isProhibited: true;
  isSuccess: true;
  message: string;
}

// Documents
export interface ILandingAgentsCreateDocumentRequest {
  crmLeadId: string;
  productCode: string;
  estimate: number;
  bankAccount: IBankAccount;
  brokerAccount: Nullable<object>;
}

export interface ILandingAgentsCreateDocumentResponse {
  crmLeadId: string;
  crmClientId: string;
  crmDocPackId: string;
}

export interface ILandingAgentsDocumentsReadyQuery {
  crmDocPackId: string;
}

export interface ILandingAgentsDocumentDownloadQuery {
  documentGUID: string;
}

// SMS Confirmation
export interface ILandingAgentsRequestSMSRequest {
  phone: string;
  codeLength: number;
}

export interface ILandingAgentsCheckSMSRequest {
  phone: string;
  code: string;
}

export interface ILandingAgentDocumentSignedRequest {
  crmDockPackId: string;
  rejectReason: number;
  smsCode: string;
  orderNumber?: string;
  orderSignDate: string;
  agentEmployeeOrderSignDate: string;
  agentEmployeeSmsCode: string;
  onboardingType: ONBOARDING_TYPE;
}

export interface ILandingAgentDocumentSignedResponse {
  contracts: IContract[];
}
