import { FC } from "react";

import { Input, SingleChips } from "ii-ui-kit";
import {
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import Autocomplete from "core/components/autocomplete";
import GENDER from "core/enums/genders";
import useSuggestions from "core/hooks/use-suggestions";
import { getMeta } from "core/utils/form";
import { IPersonalDataForm } from "pages/personal-data/types";
import { IFmsCodeSuggestion } from "services/suggestions-service/types";

type PassportDataSectionProps = {
  register: UseFormRegister<IPersonalDataForm>;
  form: UseFormReturn<IPersonalDataForm>;
  watch: UseFormWatch<IPersonalDataForm>;
  setValue: UseFormSetValue<IPersonalDataForm>;
};

const genderList = [
  { label: "Муж", value: GENDER.MALE },
  { label: "Жен", value: GENDER.FEMALE },
];

const PassportDataSection: FC<PassportDataSectionProps> = ({
  form,
  register,
  watch,
  setValue,
}) => {
  const [
    hasNoPatronymic,
    gender,
    mobile,
    fmsCode,
    passportSeriesAndNumber,
    issueDate,
    birthDate,
  ] = watch([
    "hasNoPatronymic",
    "gender",
    "mobile",
    "passportIssuedById",
    "passportSeriesAndNumber",
    "passportIssueDate",
    "birthDate",
  ]);

  const lastNameSuggestions = useSuggestions({
    form,
    key: "lastName",
    suggestionsEndpoint: "suggestName",
    queryOptions: { parts: ["SURNAME"] },
  });

  const firstNameSuggestions = useSuggestions({
    form,
    key: "firstName",
    suggestionsEndpoint: "suggestName",
    queryOptions: { parts: ["NAME"] },
  });

  const middleNameSuggestions = useSuggestions({
    form,
    key: "patronymic",
    suggestionsEndpoint: "suggestName",
    queryOptions: { parts: ["PATRONYMIC"] },
  });

  const fmsCodeSuggestions = useSuggestions({
    form,
    suggestionsEndpoint: "suggestFmsCode",
    key: "passportIssuedById",
  }) as IFmsCodeSuggestion[];

  return (
    <div className="personal-data__section">
      <div className="personal-data__subtitle">Паспортные данные</div>
      <Autocomplete
        options={lastNameSuggestions}
        onOptionClick={({ value }) => setValue("lastName", value)}
        optionRender={({ value }) => value}
        componentProps={{
          inputProps: {
            id: "lastName",
            label: "Фамилия",
            placeholder: "Фамилия",
            ...register("lastName"),
            ...getMeta(form, "lastName"),
          },
        }}
      />
      <Autocomplete
        options={firstNameSuggestions}
        onOptionClick={({ value }) => setValue("firstName", value)}
        optionRender={({ value }) => value}
        componentProps={{
          inputProps: {
            id: "firstName",
            label: "Имя",
            placeholder: "Имя",
            ...register("firstName"),
            ...getMeta(form, "firstName"),
          },
        }}
      />
      <Autocomplete
        options={middleNameSuggestions}
        onOptionClick={({ value }) => setValue("patronymic", value)}
        optionRender={({ value }) => value}
        componentProps={{
          inputProps: {
            id: "middleName",
            label: "Отчество",
            placeholder: "Отчество",
            disabled: hasNoPatronymic,
            ...register("patronymic"),
            ...getMeta(form, "patronymic"),
          },
          switchProps: {
            id: "hasNoPatronymic",
            label: "Нет",
            value: hasNoPatronymic,
            onChange: (value: boolean) => {
              if (value) {
                form.setValue("patronymic", "");
              }
              form.setValue("hasNoPatronymic", value);
            },
          },
        }}
      />
      <Input
        id="mobile"
        label="Мобильный телефон"
        placeholder="Мобильный телефон"
        // eslint-disable-next-line no-nonoctal-decimal-escape
        mask="+7 (\9**) *** ** **"
        inputMode="numeric"
        value={mobile}
        {...register("mobile")}
        {...getMeta(form, "mobile")}
      />
      <Input
        id="email"
        label="Электронная почта"
        placeholder="Электронная почта"
        autoComplete="email"
        inputMode="email"
        {...register("email")}
        {...getMeta(form, "email")}
      />
      <Input
        id="passportSeriesAndNumber"
        label="Серия и номер паспорта"
        placeholder="Серия и номер паспорта"
        inputMode="numeric"
        mask="9999 999999"
        value={passportSeriesAndNumber}
        {...register("passportSeriesAndNumber")}
        {...getMeta(form, "passportSeriesAndNumber")}
      />
      <Autocomplete
        componentProps={{
          inputProps: {
            id: "passportIssuedById",
            label: "Код подразделения",
            placeholder: "Код подразделения",
            inputMode: "numeric",
            mask: "999-999",
            ...register("passportIssuedById"),
            ...getMeta(form, "passportIssuedById"),
            value: fmsCode,
            onChange: (e) => setValue("passportIssuedById", e.target.value),
          },
        }}
        options={fmsCodeSuggestions}
        onOptionClick={({ value, data: { code } }) => {
          setValue("passportIssuedById", code);
          setValue("passportIssuedBy", value);
        }}
        optionRender={({ value, data: { code } }) => `${code}, ${value}`}
      />
      <Input
        id="passportIssuedBy"
        label="Кем выдан"
        placeholder="Кем выдан"
        {...register("passportIssuedBy")}
        {...getMeta(form, "passportIssuedBy")}
      />
      <Input
        id="passportIssueDate"
        label="Дата выдачи"
        placeholder="Дата выдачи"
        mask="99.99.9999"
        inputMode="numeric"
        value={issueDate}
        {...register("passportIssueDate")}
        {...getMeta(form, "passportIssueDate")}
      />
      <Input
        id="client.birthPlace"
        label="Место рождения"
        placeholder="Место рождения"
        inputMode="text"
        {...register("birthPlace")}
        {...getMeta(form, "birthPlace")}
      />
      <Input
        id="birthDay"
        label="Дата рождения"
        placeholder="Дата рождения"
        mask="99.99.9999"
        autoComplete="bday"
        inputMode="numeric"
        value={birthDate}
        {...register("birthDate")}
        {...getMeta(form, "birthDate")}
      />
      <SingleChips
        className="form__row"
        size="middle"
        items={genderList}
        onChange={(value) =>
          setValue("gender", (value as GENDER) || GENDER.MALE)
        }
        value={gender}
      />
    </div>
  );
};

export default PassportDataSection;
