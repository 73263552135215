/** Регулярка для имени или фамилии только русские буквы дефис и пробел (посередине)*/
export const NAME_REGEX = /^[ЁА-яё]+[\s-]?[ЁА-яё]+$/;

/** Регулярка отчества*/
export const PATRONYMIC_REGEX = /^[ЁА-яё]+([\s-]?[ЁА-яё]+)?$/;

export const CYRILLIC_REGEX = /^[\s",.ЁА-яё-]+$/;

/** Регулярка исключающая латиницу */
export const NOT_LATIN_REGEX = /^[^A-z]*$/;

/** Регулярка для email (латинский алфавит, знак собаки, точка) */
export const EMAIL_REGEX = /^[\w+.-]+@[\wЁА-яё]{2,}\.[\wЁА-яё]{2,}$/;

/** Регулярка для места рождения */
export const BIRTH_PLACE_REGEX = /^([ЁА-яё]+[\s,.-]*)+[ЁА-яё]$/;

/** Регулярка для адреса */
export const ADDRESS_REGEX = /^([\dЁА-яё]+[\s,./-]*)+[\dЁА-яё]$/;

/** Регулярка для phone (в формате:  +7 или 8 999 999 99 99, первые три цифры в скобках или без, между остальными пробел или тире*/
export const PHONE_REGEX =
  /^(\+7|8)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}(?:[\s-]?\d{2}){2}$/;

/** Регулярка для кода подразделения паспорта */
export const DEPARTMENT_CODE_REGEX = /^\d{3}-\d{3}$/;

/** Регулярка для номера паспорта */
export const PASSPORT_NUMBER_REGEX = /^\d{4}\s\d{6}$/;

/** Регулярка для расчетного счета физ.лица */
export const CHECKING_ACCOUNT_REGEX = /^408\d{17}$/;

/** Регулярка для банка или бик */
export const BANK_OR_BIK_REGEX = /^[\d\s"()«»ЁА-яё№]*$/;

/** Регулярка для проверки отсутствия пробелов по краям */
export const NO_EDGE_SPACES_REGEX = /^\S+(\s+\S+)*$/;
