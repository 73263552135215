import { FC } from "react";

import { Button, Modal } from "ii-ui-kit";
import "./index.scss";

type ContinueModalProps = {
  isShown: boolean;
  title: string;
  text: string;
  onClose: () => void;
  onContinue: () => void;
};

export const ContinueModal: FC<ContinueModalProps> = ({
  isShown,
  text,
  title,
  onClose,
  onContinue,
}) => {
  return (
    <Modal className="continue-modal" shown={isShown} onClose={onClose}>
      <div className="continue-modal__header">{title}</div>
      <div className="continue-modal__text">{text}</div>
      <div className="continue-modal__footer">
        <Button size="l" color="secondary" onClick={onClose}>
          Назад
        </Button>
        <Button
          size="l"
          className="continue-modal__exit-btn"
          color="primary"
          onClick={onContinue}
        >
          Продолжить
        </Button>
      </div>
    </Modal>
  );
};
