import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { CLIENT_SIGN_BASE_API_URL } from "const";
import { ICommonResponse } from "services/common-types";

import {
  ICreateLinkRequest,
  ICreateLinkResponse,
  IDocumentSignRequest,
  IDocumentsListResponse,
  IDocumentStatusResponse,
} from "./types";

/** Апи агентского подписания */
export const agentClientSignApi = createApi({
  reducerPath: "agentClientSignApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${CLIENT_SIGN_BASE_API_URL}api/AgentClientSignDocuments/`,
  }),
  endpoints: (builder) => ({
    /** Генерация и отправка ссылки для подписания клиентом */
    createLink: builder.mutation<
      ICommonResponse<ICreateLinkResponse>,
      ICreateLinkRequest
    >({
      query: (body) => ({
        url: "CreateLink",
        method: "POST",
        body,
      }),
    }),
    /** Получение статуса подписания документов */
    documentStatus: builder.query<
      ICommonResponse<IDocumentStatusResponse>,
      { linkId: string }
    >({
      query: (params) => ({
        url: "DocumentStatus",
        method: "GET",
        params,
      }),
    }),
    /** Получение списка документов для подписания */
    documentList: builder.query<
      ICommonResponse<IDocumentsListResponse>,
      { linkId: string }
    >({
      query: (params) => ({
        url: "DocumentList",
        method: "GET",
        params,
      }),
    }),
    /** Фиксация факта подписания документов */
    documentSign: builder.mutation<ICommonResponse<null>, IDocumentSignRequest>(
      {
        query: (body) => ({
          url: "DocumentSign",
          method: "POST",
          body,
        }),
      }
    ),
  }),
});
