export enum DATE_FORMAT {
  YEAR = "yyyy",
  MM_YEAR = "MM.yyyy",
  FULL_DATE_API = "yyyy-MM-dd",
  FULL_DATE_FULL_MONTH = "dd MMMM yyyy",
  FULL_DATE = "dd.MM.yyyy",
  FULL_DATE_WITH_DASH_REVERSE = "yyyy-MM-dd",
  FULL_DATE_WITH_DASH = "dd-MM-yyyy",
  FULL_DATE_AND_FULL_TIME = "dd.MM.yyyy HH:mm:ss",
  FULL_DATE_AND_TIME = "dd.MM.yyyy HH:mm",
  TIME_AND_FULL_DATE = "HH:mm - dd.MM.yyyy",
}
