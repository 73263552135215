import { ProductType } from "core/types/entities/product-type";

import { IPageContent } from "../types";

/** Контент для ПИФ */
const pifPageContent: IPageContent = {
  title: "Выбор фонда и суммы инвестирования",
  productLabel: "Фонд",
  amountLabel: "Сумма, ₽",
};

/** Контент для ДУ и ИИС */
const tmAndIiaContent: IPageContent = {
  title: "Выбор стратегии и суммы инвестирования",
  productLabel: "Стратегия",
  amountLabel: "Сумма инвестирования",
};

/** Контент для страницы */
export const pageContent = {
  [ProductType.PIF]: pifPageContent,
  [ProductType.DU]: tmAndIiaContent,
  [ProductType.IIS]: tmAndIiaContent,
};
