import { FC, useEffect, useState } from "react";

import { Button, DropZone, Modal, useBreakpoint } from "ii-ui-kit";

import "./index.scss";
import ErrorMessage from "core/components/error-message";
import {
  DOCUMENTS_MOBILE_SIZE,
  DOCUMENTS_SIZE,
  DOCUMENT_COUNT,
} from "pages/documents/const";

type RealSignModalProps = {
  isShow: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const RealSignModal: FC<RealSignModalProps> = ({
  isShow,
  onClose,
  onContinue,
}) => {
  const isMobile = useBreakpoint((builder) => builder.under("md"));

  const [documents, setDocuments] = useState<File[]>([]);
  const [isDisplayError, setIsDisplayError] = useState(false);

  useEffect(() => {
    if (documents && documents.length === DOCUMENT_COUNT) {
      setIsDisplayError(false);
    }
  }, [documents]);

  const handleContinueClick = () => {
    if (documents && documents.length < DOCUMENT_COUNT) {
      setIsDisplayError(true);
      return;
    }

    setIsDisplayError(false);

    onContinue();
  };

  return (
    <Modal swipeable shown={isShow} closable onClose={onClose}>
      <div className="real-sign-modal">
        <div className="real-sign-modal__title">
          Приложите сканы подписанных документов
        </div>
        <div className="real-sign-modal__documents">
          <DropZone
            title="Приложите сканы документов"
            maxSize={isMobile ? DOCUMENTS_MOBILE_SIZE : DOCUMENTS_SIZE}
            accept={[".png", ".jpeg", ".jpg", ".pdf", ".tiff"]}
            onChange={(value) => {
              // TODO когда буду делать интеграцию с сервисами разобраться в каком формате кидать файлы
              // eslint-disable-next-line no-console
              console.log(value);

              setDocuments(value);
            }}
          />
          {isDisplayError && (
            <ErrorMessage errorText="Приложите сканы документов" />
          )}
        </div>
        <Button
          onClick={handleContinueClick}
          size="l"
          className="real-sign-modal__button"
        >
          Продолжить
        </Button>
      </div>
    </Modal>
  );
};

export default RealSignModal;
