import { FC } from "react";

import classNames from "classnames";
import { PortalProps, Portal, Informer } from "ii-ui-kit";

import {
  SnackbarState,
  SnackbarPosition,
} from "core/store/reducers/app-reducer/types";

import "./snackbar.scss";

interface ISnackBarProps extends SnackbarState {
  position: SnackbarPosition;
  portalContainer: PortalProps["container"];
  onClose: () => void;
}

export const Snackbar: FC<ISnackBarProps> = ({
  position,
  portalContainer,
  title,
  text,
  type,
  isOpen,
  buttonText,
  onButtonClick,
  onClose,
}) => {
  return isOpen ? (
    <Portal container={portalContainer}>
      <Informer
        className={classNames("snackbar", `snackbar--${position}`)}
        isOpen
        title={title}
        text={text}
        isDisplayIcon
        isClosable
        type={type}
        buttonText={buttonText}
        onButtonClick={onButtonClick}
        onClose={onClose}
      />
    </Portal>
  ) : null;
};
