import React, { RefCallback } from "react";

import classNames from "classnames";
import { CheckboxItem, CheckboxItemData } from "ii-ui-kit";
import {
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import ErrorMessage from "core/components/error-message";

import "./index.scss";

type Props<T extends FieldValues> = Omit<CheckboxItemData, "value"> & {
  onChange: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  name: string;
  ref: RefCallback<HTMLInputElement>;
  errorText?: string;
  className?: string;
};

const CheckboxInput = <T extends FieldValues>({
  onChange,
  watch,
  name,
  ref,
  errorText,
  className,
  ...checkboxProps
}: Props<T>) => {
  const value = watch(name as Path<T>);
  return (
    <>
      <CheckboxItem
        size="middle"
        className={classNames("checkbox-input", className)}
        ref={ref}
        value={name}
        checked={value}
        onChange={(checked) =>
          onChange(name as Path<T>, checked as T[typeof name])
        }
        {...checkboxProps}
      />
      {errorText && <ErrorMessage errorText={errorText} />}
    </>
  );
};

export default CheckboxInput;
