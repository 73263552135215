import { Navigate, Outlet } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppSelector } from "core/store/hooks";
import { authSelectors } from "core/store/reducers/auth-reducer";

export const ProtectedRoute = () => {
  const isUserAuthorized = useAppSelector(authSelectors.selectAgentAuthorized);

  if (!isUserAuthorized) {
    return <Navigate to={AppRoutes.Auth} replace />;
  }

  return <Outlet />;
};
