import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASE_API_URL } from "const";

import {
  IMerchantQrcDataResponse,
  IMerchantQrcDataRequest,
  IMerchantFrontDataRequest,
  IMerchantQrcStatusRequest,
  IMerchantQrcStatusResponse,
  IMerchantRequestCallbackRequest,
  IMerchantRequestCallbackResponse,
} from "./types";

export const merchantApi = createApi({
  reducerPath: "merchantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}api/Merchant`,
  }),
  endpoints: (builder) => ({
    qrcData: builder.mutation<
      IMerchantQrcDataResponse,
      IMerchantQrcDataRequest
    >({
      query: (body) => ({
        url: "/QrcData",
        method: "POST",
        body,
      }),
    }),

    frontData: builder.mutation<void, IMerchantFrontDataRequest>({
      query: (body) => ({
        url: "/FrontData",
        method: "POST",
        body,
      }),
    }),

    qrcStatus: builder.mutation<
      IMerchantQrcStatusResponse,
      IMerchantQrcStatusRequest
    >({
      query: (body) => ({
        url: "/QrcStatus",
        method: "POST",
        body,
      }),
    }),

    requestCallback: builder.mutation<
      IMerchantRequestCallbackResponse,
      IMerchantRequestCallbackRequest
    >({
      query: (body) => ({
        url: "/RequestCallback",
        method: "POST",
        body,
      }),
    }),
  }),
});
