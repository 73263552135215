import React, { useCallback } from "react";

import "./index.scss";

type Props<T> = {
  option: T;
  optionRender?: (option: T) => React.ReactNode;
  onOptionClick?: (option: T) => void;
};

const defaultOptionRender = <T,>(option: T) => option;

const AutocompleteOption = <T,>({
  option,
  optionRender = defaultOptionRender,
  onOptionClick,
}: Props<T>) => {
  const handleSuggestionClick = useCallback(() => {
    onOptionClick && onOptionClick(option);
  }, [option, onOptionClick]);
  return (
    <div onClick={handleSuggestionClick} className="autocomplete__suggestion">
      {optionRender(option)}
    </div>
  );
};

export default AutocompleteOption;
