import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import "ii-ui-kit/dist/styles.css";

import ScrollToTop from "core/components/scroll-to-top";
import { persistor, store } from "core/store";

import App from "./app";
import "./styles/styles.scss";

render(
  <Provider store={store}>
    <PersistGate loading="Loading..." persistor={persistor}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
