import { formatISO } from "date-fns";

import { DATE_FORMAT } from "core/enums/date-format";

import { formatDate } from "./date-formatters";
import { pipe } from "./fp";

/** Отформатировать дату к ISO с учетом часового пояса */
export const formatDateToIso = (date: Date | string) => {
  const result = pipe(
    date,
    (value) => formatDate(value, DATE_FORMAT.FULL_DATE_WITH_DASH_REVERSE),
    (value) => new Date(value),
    (value) => formatISO(value)
  );

  return result;
};
