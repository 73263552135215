import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppSelector } from "core/store/hooks";
import { personalDataSelectors } from "core/store/reducers/personal-data-reducer";
import { useDocumentsPageInitialize } from "pages/documents/page-hooks";

export const useDocumentsSuccessPageState = () => {
  useDocumentsPageInitialize();

  const navigate = useNavigate();
  const handleButtonClick = useCallback(() => {
    navigate(AppRoutes.Payment);
  }, [navigate]);

  const { email } = useAppSelector(personalDataSelectors.selectPersonalData);

  return {
    handleButtonClick,
    email,
  };
};
