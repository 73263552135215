import { FC, useMemo } from "react";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useSearchParams } from "react-router-dom";

import { INGOSINVEST_SITE } from "const";
import { BackdropLoader } from "core/components/backdrop-loader";
import { RenderRemote } from "core/components/render-remote";
import { isFetchBaseQueryError } from "core/utils/api-error-utils";
import { pipe, RemoteData } from "core/utils/fp";
import ClientDocumentsErrorModal from "pages/client-documents/client-documents-error-modal";
import {
  agentClientSignApi,
  IDocumentsListResponse,
} from "services/agent-client-sign";
import { ICommonResponse } from "services/common-types";

import ClientDocuments from "./client-documents";
import { ClientDocumentsPageParams } from "./types";

export const ClientDocumentsContainer: FC = () => {
  const [searchParams] = useSearchParams();

  const { linkId }: ClientDocumentsPageParams = useMemo(() => {
    const linkId = searchParams.get("linkId") || "";

    return {
      linkId,
    };
  }, [searchParams]);

  const queryResponse: RemoteData<ICommonResponse<IDocumentsListResponse>> =
    agentClientSignApi.useDocumentListQuery({ linkId });

  const data = pipe(queryResponse);

  const errorDescription: string = useMemo(() => {
    if ("error" in data && isFetchBaseQueryError(data.error)) {
      const errorData = (data.error as FetchBaseQueryError)
        .data as ICommonResponse<unknown>;

      return errorData.exception?.description;
    }

    return "";
  }, [data]);

  return (
    <RenderRemote
      data={data}
      success={(data) => (
        <ClientDocuments documentsData={data.body} linkId={linkId} />
      )}
      pending={<BackdropLoader isLoading />}
      error={
        <ClientDocumentsErrorModal
          isOpen
          description={errorDescription}
          onClose={() => window.open(INGOSINVEST_SITE, "_self")}
        />
      }
    />
  );
};
