import React from "react";

import { Link } from "react-router-dom";

import logo from "assets/logo/logo.svg";

export const Logo: React.FC = () => (
  <Link to="/">
    <img src={logo} alt="ингосстрах-инвестиции логотип" />
  </Link>
);
