import { FC, useState } from "react";

import { DropZone } from "ii-ui-kit";
import { UseFormReturn, UseFormSetValue } from "react-hook-form";

import ErrorMessage from "core/components/error-message";
import { PassportPage } from "core/types/entities/passport";
import { getMeta } from "core/utils/form";
import { IPersonalDataForm } from "pages/personal-data/types";
import { agentsLandingApi } from "services/agents-landing-service";

type LoadPassportSectionProps = {
  form: UseFormReturn<IPersonalDataForm>;
  setValue: UseFormSetValue<IPersonalDataForm>;
};

const LoadPassportSection: FC<LoadPassportSectionProps> = ({
  form,
  setValue,
}) => {
  const [uploadFileMutation] = agentsLandingApi.useUploadFileMutation();

  const [isLoadingPages, setIsLoadingPages] = useState<
    Record<PassportPage, boolean>
  >({ [PassportPage.Main]: false, [PassportPage.Secondary]: false });

  const uploadFile = async (file: File, page: PassportPage) => {
    const fieldName =
      page === PassportPage.Main
        ? "personDocumentScan.0.documentGUID"
        : "personDocumentScan.1.documentGUID";

    if (!file) {
      setValue(fieldName, "", {
        shouldValidate: true,
      });

      return;
    }

    setIsLoadingPages((prev) => ({ ...prev, [page]: true }));

    await uploadFileMutation(file)
      .unwrap()
      .then(({ body }) => {
        setValue(fieldName, body.guid, { shouldValidate: true });
      })
      .catch(() => {
        form.setError(fieldName, {
          message: "Ошибка загрузки. Попробуйте заново",
        });
      })
      .finally(() => {
        setIsLoadingPages((prev) => ({ ...prev, [page]: false }));
      });
  };

  const mainPageError = getMeta(
    form,
    "personDocumentScan.0.documentGUID"
  ).errorText;

  const secondaryPageError = getMeta(
    form,
    "personDocumentScan.1.documentGUID"
  ).errorText;

  return (
    <div className="personal-data__section">
      <div className="personal-data__subtitle">Скан паспорта</div>
      <div>
        <DropZone
          maxFiles={1}
          title="Приложите разворот с фото"
          accept={[".png", ".jpeg", ".jpg", ".pdf", ".tiff"]}
          isLoading={isLoadingPages[PassportPage.Main]}
          onChange={([file]) => uploadFile(file, PassportPage.Main)}
        />
        {mainPageError && <ErrorMessage errorText={mainPageError} />}
      </div>

      <div>
        <DropZone
          maxFiles={1}
          title="Приложите разворот с адресом регистрации"
          accept={[".png", ".jpeg", ".jpg", ".pdf", ".tiff"]}
          isLoading={isLoadingPages[PassportPage.Secondary]}
          onChange={([file]) => uploadFile(file, PassportPage.Secondary)}
        />
        {secondaryPageError && <ErrorMessage errorText={secondaryPageError} />}
      </div>
    </div>
  );
};

export default LoadPassportSection;
