import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Reducers } from "core/enums/reducers";
import { appActions } from "core/store/reducers/app-reducer";
import { IAgentInfoResponse } from "services/auth-service/types";

import { IAuthState } from "./types";

const initialState: IAuthState = {
  agentLoginPhone: "",
  agentEmployeeID: "",
  agentInfo: {},
};

const authSlice = createSlice({
  name: Reducers.Auth,
  initialState,
  reducers: {
    setAgentPhone: (state, { payload }: PayloadAction<string>) => {
      state.agentLoginPhone = payload;
    },
    setAgentEmployeeID: (state, { payload }: PayloadAction<string>) => {
      state.agentEmployeeID = payload;
    },
    setAgentInfo: (state, { payload }: PayloadAction<IAgentInfoResponse>) => {
      state.agentInfo = payload;
    },
    clearStore: () => {
      return initialState;
    },
  },
  extraReducers: {
    [appActions.clearStore.type]: () => {
      return initialState;
    },
  },
});

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;
