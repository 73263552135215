import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Reducers } from "core/enums/reducers";
import { appActions } from "core/store/reducers/app-reducer";

import IPaymentState from "./types";

const initialState: IPaymentState = {
  applicationNumber: "",
  qrLink: "",
  qrcId: "",
  qrName: "",
  fund: "",
  fundId: 0,
  amount: 0,
  account: "",
  merchantId: "",
  currency: "",
  orderDate: "",
  orderNumber: "",
};

const paymentSlice = createSlice({
  name: Reducers.Payment,
  initialState,
  reducers: {
    setPaymentInfo: (
      state,
      { payload }: PayloadAction<Partial<IPaymentState>>
    ) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [appActions.clearStore.type]: () => {
      return initialState;
    },
  },
});

export const paymentActions = paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
