import { useEffect } from "react";

import { useLocation } from "react-router-dom";

/** Скрол наверх при изменении маршрута */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollTo({ behavior: "smooth", left: 0, top: 0 });
  }, [pathname]);

  return null;
};

export default ScrollToTop;
