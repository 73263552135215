import { Reducers } from "core/enums/reducers";
import { RootState } from "core/store";

const selectPersonalData = (state: RootState) => state[Reducers.PersonalData];

const selectName = (state: RootState) => {
  const { firstName, lastName, patronymic } = state[Reducers.PersonalData];
  return { firstName, lastName, patronymic };
};

const selectFullName = (state: RootState) => {
  const { firstName, lastName, patronymic } = state[Reducers.PersonalData];
  return patronymic
    ? `${lastName} ${firstName} ${patronymic}`
    : `${lastName} ${firstName}`;
};

export const personalDataSelectors = {
  selectPersonalData,
  selectName,
  selectFullName,
};
