import { differenceInYears } from "date-fns";
import * as yup from "yup";

import { parseDateString } from "core/utils/date-formatters";
import {
  NAME_REGEX,
  CYRILLIC_REGEX,
  PHONE_REGEX,
  DEPARTMENT_CODE_REGEX,
  PASSPORT_NUMBER_REGEX,
  PATRONYMIC_REGEX,
  ADDRESS_REGEX,
  NOT_LATIN_REGEX,
  BIRTH_PLACE_REGEX,
  EMAIL_REGEX,
  NO_EDGE_SPACES_REGEX,
} from "core/utils/regex-rules";

/** Валидация формы подписки на раскрываемую информацию */
export const personalDataFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Заполните имя")
    .max(40, "Превышена максимальная длина")
    .matches(NOT_LATIN_REGEX, "Допустим ввод только на кириллице")
    .matches(NAME_REGEX, "Проверьте корректность введенных данных"),
  lastName: yup
    .string()
    .required("Заполните фамилию")
    .max(40, "Превышена максимальная длина")
    .matches(NOT_LATIN_REGEX, "Допустим ввод только на кириллице")
    .matches(NAME_REGEX, "Проверьте корректность введенных данных"),
  patronymic: yup.string().when("hasNoPatronymic", {
    is: false,
    then: (schema) =>
      schema
        .required("Заполните отчество")
        .matches(NOT_LATIN_REGEX, "Допустим ввод только на кириллице")
        .matches(PATRONYMIC_REGEX, "Проверьте корректность введенных данных")
        .max(40, "Превышена максимальная длина"),
  }),
  mobile: yup
    .string()
    .required("Укажите номер телефона")
    .matches(PHONE_REGEX, "Введите корректный номер телефона"),
  email: yup
    .string()
    .required("Укажите адрес электронной почты")
    .matches(EMAIL_REGEX, "Проверьте адрес электронной почты")
    .max(200, "Превышена максимальная длина"),
  passportSeriesAndNumber: yup
    .string()
    .required("Укажите серию и номер паспорта")
    .matches(PASSPORT_NUMBER_REGEX, "Проверьте корректность введённых данных"),
  passportIssuedById: yup
    .string()
    .required("Укажите код подразделения")
    .matches(DEPARTMENT_CODE_REGEX, "Проверьте корректность введённых данных"),
  passportIssuedBy: yup
    .string()
    .required("Укажите орган, выдавший документ")
    .matches(CYRILLIC_REGEX, "Проверьте корректность введенных данных")
    .matches(NO_EDGE_SPACES_REGEX, "Проверьте корректность введенных данных")
    .max(200, "Превышена максимальная длина"),
  passportIssueDate: yup
    .string()
    .required("Укажите дату рождения")
    .test(
      "More than today",
      "Дата больше текущей, укажите корректное значение",
      (value) => {
        const result =
          new Date() > ((value && parseDateString(value)) || new Date());

        return result;
      }
    )
    .test(
      "Max date of issue test",
      "Проверьте корректность введённых данных",
      (value) => {
        const result =
          differenceInYears(
            new Date(),
            (value && parseDateString(value)) || new Date()
          ) <= 100;

        return result;
      }
    ),

  birthPlace: yup
    .string()
    .required("Заполните место рождения")
    .matches(BIRTH_PLACE_REGEX, "Проверьте корректность введенных данных")
    .max(200, "Превышена максимальная длина"),
  birthDate: yup
    .string()
    .required("Укажите дату рождения")
    .test(
      "More than today",
      "Дата больше текущей, укажите корректное значение",
      (value) => {
        const result =
          new Date() > ((value && parseDateString(value)) || new Date());

        return result;
      }
    )
    .test(
      "Min birth day test",
      "Минимальный возраст клиента УК ИИ 18 лет",
      (value) => {
        const result =
          differenceInYears(
            new Date(),
            (value && parseDateString(value)) || new Date()
          ) >= 18;

        return result;
      }
    )
    .test(
      "Max birth day test",
      "Проверьте корректность введённых данных",
      (value) => {
        const result =
          differenceInYears(
            new Date(),
            (value && parseDateString(value)) || new Date()
          ) <= 100;

        return result;
      }
    ),
  registerAddress: yup.object().shape({
    stringAddress: yup
      .string()
      .required("Укажите адрес регистрации")
      .matches(ADDRESS_REGEX, "Проверьте корректность введенных данных")
      .max(200, "Превышена максимальная длина"),
  }),

  liveAddress: yup.object().shape({
    stringAddress: yup.string().when(["isRegisterAddressSameAsLiveAddress"], {
      is: false,
      then: (schema) =>
        schema
          .required("Укажите фактический адрес проживания")
          .matches(ADDRESS_REGEX, "Проверьте корректность введенных данных")
          .max(200, "Превышена максимальная длина"),
    }),
  }),

  inn: yup
    .string()
    .required("Укажите ИНН")
    .length(12, "ИНН должен содержать 12 цифр"),

  personDocumentScan: yup.array().of(
    yup.object().shape({
      documentGUID: yup.string().required("Загрузите документ"),
    })
  ),
});
