import { useEffect } from "react";

import { useAppSelector, useAppDispatch } from "core/store/hooks";

import { SnackbarPosition, SnackbarState } from "./types";

import { appActions, appSelectors } from ".";

export const useSnackbar = (position?: SnackbarPosition) => {
  const snackbarState = useAppSelector(appSelectors.selectSnackbarState);
  const dispatch = useAppDispatch();

  const setSnackbarAlert = ({
    text,
    type,
    title,
    buttonText,
    onButtonClick,
  }: Omit<SnackbarState, "isOpen">) => {
    dispatch(
      appActions.setSnackbarAlert({
        isOpen: true,
        text,
        type,
        title,
        buttonText,
        onButtonClick,
      })
    );
  };

  const clearSnackbarAlert = () => {
    dispatch(appActions.clearSnackbarAlert());
  };

  useEffect(() => {
    if (position) {
      dispatch(appActions.setSnackbarPosition(position));
    }
  }, [dispatch, position]);

  return { actions: { setSnackbarAlert, clearSnackbarAlert }, snackbarState };
};
