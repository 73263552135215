import { expandTemplateString } from "./string-utils";

/** Форматирует номер телефона к единому шаблону: +7 (999) 999-99-99 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const cleanedTrimAndChangeFirstNumber = phoneNumber
    .replace(/\D/g, "")
    .replace(/^\d/, "7");
  return expandTemplateString(
    "+9 (999) 999-99-99",
    cleanedTrimAndChangeFirstNumber
  );
};

/** Форматирует номер телефона к шаблону: +79999999999 */
export const formatPhoneNumberWithoutSpacesAndBrackets = (
  phoneNumber: string
) => {
  return `+${phoneNumber.replace(/\D/g, "")}`;
};
