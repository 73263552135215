/** Тип блока */
export enum ADDRESS_BLOCK_TYPE {
  /** Строение */
  BUILDING = "стр",
  /** Корпус */
  HOUSING = "к",
}

export interface ISuggestionRequest {
  query: string;
  count?: number;
  parts?: string[];
}

export interface ISuggestion {
  value: string;
}

export interface IAddressSuggestion extends ISuggestion {
  data: {
    postal_code: string;
    country: string;
    /** Регион с типом */
    region_with_type: string;
    city: string;
    /** Улица*/
    street_with_type: string;
    house: string;
    flat: string;
    /** Идентификатор региона (КЛАДР) */
    region_kladr_id: string;
    fias_id: string;
    kladr_id: string;
    block: string;
    block_type: ADDRESS_BLOCK_TYPE;
  };
}

export interface IBankSuggestion extends ISuggestion {
  data: {
    address: {
      value: string;
    };
    name: {
      short: string;
    };
    bic: string;
    inn: string;
    correspondent_account: string;
  };
}

export interface IFmsCodeSuggestion extends ISuggestion {
  data: {
    code: string;
    name: string;
  };
}

export interface ISuggestionsResponse<T extends ISuggestion> {
  suggestions: T[];
}
