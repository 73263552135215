import { FieldValues, Path, UseFormReturn } from "react-hook-form";

import { suggestionsApi } from "services/suggestions-service";
import { ISuggestionRequest } from "services/suggestions-service/types";

interface IUseSuggestionsParams<T extends FieldValues> {
  form: UseFormReturn<T>;
  key: Path<T>;
  suggestionsEndpoint: keyof typeof suggestionsApi.endpoints;
  queryOptions?: Omit<ISuggestionRequest, "query">;
}

const useSuggestions = <T extends FieldValues>({
  form,
  key,
  suggestionsEndpoint,
  queryOptions,
}: IUseSuggestionsParams<T>) => {
  const observedValue: string = form.watch(key);
  const suggestionsResponse = suggestionsApi.endpoints[
    suggestionsEndpoint
  ].useQuery({
    query: observedValue,
    count: 6,
    ...queryOptions,
  });

  const data = suggestionsResponse.data?.suggestions || [];

  return data;
};

export default useSuggestions;
