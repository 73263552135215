/** Сайт УК */
export const INGOSINVEST_SITE = "https://www.ingosinvest-am.ru";

/** Сайт УК без протокола */
export const INGOSINVEST_SITE_WITHOUT_PROTOCOL = "www.ingosinvest-am.ru";

/** Урл к апи */
export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ?? "http://localhost:4001/";
export const PRODUCTS_BASE_API_URL =
  process.env.REACT_APP_PRODUCTS_API_URL ?? "http://localhost:4001/";

/** Урл к сервису клиентского подписания */
export const CLIENT_SIGN_BASE_API_URL =
  process.env.REACT_APP_CLIENT_SIGN_BASE_API_URL ?? "http://localhost:4001/";

export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_DEV = process.env.NODE_ENV === "development";

export const IS_TESTING_STAGING = process.env.REACT_APP_STAGING === "testing";

export const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;

export const SUGGESTIONS_API_TOKEN =
  process.env.REACT_APP_SUGGESTIONS_API_TOKEN ??
  "85e8b43a133c7e2e27471b9ba0d60027beeb2cb1";

export const SPB_MAX_INVEST_AMOUNT = 999999.99;

// LocalStorage keys
export const PRODUCT_TYPE_DATA_KEY = "product-type";
export const PERSONAL_DATA_DATA_KEY = "personal-data";
export const CLIENT_CHECK_DATA_KEY = "client-check";
export const FUND_SELECT_DATA_KEY = "fund-select";
