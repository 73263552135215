const tmStrategies = [
  {
    shareId: 39353077,
    name: "Рублевые облигации",
    shortName: "Рублевые облигации",
    qrName: "Рублевые облигации",
    available: true,
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    account: "40701810800000028886",
    merchantId: "MA0000659990",
    minLimit: 1000000,
    maxLimit: 15000000,
  },
  {
    shareId: 43337916,
    name: "Сбалансированный портфель",
    shortName: "Сбалансированный портфель",
    qrName: "Сбалансированный портфель",
    available: false,
    merchantId: "MA0000660027",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000000,
    maxLimit: 15000000,
    account: "40701810800000028886",
  },
  {
    shareId: 43338118,
    name: "Ингосстрах Россия",
    shortName: "Ингосстрах Россия",
    qrName: "Ингосстрах Россия",
    available: false,
    merchantId: "MA0000660058",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 100000,
    maxLimit: 15000000,
    account: "40701810800000028886",
  },
  {
    shareId: 43338144,
    name: "Акции российских компаний",
    shortName: "Акции российских компаний",
    qrName: "Акции российских компаний",
    available: true,
    merchantId: "MA0000659987",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000000,
    maxLimit: 15000000,
    account: "40701810800000028886",
  },
  {
    shareId: 43338147,
    name: "Инфляционная",
    shortName: "Инфляционная",
    qrName: "Инфляционная",
    available: false,
    merchantId: "MA0000659982",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 100000,
    maxLimit: 15000000,
    account: "40701810800000028886",
  },
  {
    shareId: 43338156,
    name: "Корпоративные облигации",
    shortName: "Корпоративные облигации",
    qrName: "Корпоративные облигации",
    available: false,
    merchantId: "MA0000659984",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 100000,
    maxLimit: 15000000,
    account: "40701810800000028886",
  },
  {
    shareId: 44150655,
    name: "Облигации в юанях",
    shortName: "Облигации в юанях",
    qrName: "Облигации в юанях",
    available: true,
    merchantId: "MA0000660066",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 100000,
    maxLimit: 15000000,
    account: "40701810800000028886",
  },
  {
    shareId: 48698480,
    name: "Валютные облигации",
    shortName: "Валютные облигации",
    qrName: "Валютные облигации",
    available: true,
    merchantId: "MA0000936801",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000000,
    account: "40701810800000028886",
  },
];

export default tmStrategies.filter((strategy) => strategy.available);
