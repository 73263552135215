import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASE_API_URL } from "const";
import {
  ILandingAgentsDocumentDownloadQuery,
  ILandingAgentDocumentSignedRequest,
  ILandingAgentDocumentSignedResponse,
  ILandingAgentsCheckSMSRequest,
  ILandingAgentsClientCheckResultQuery,
  ILandingAgentsClientCheckResultResponse,
  ILandingAgentsCreateDocumentRequest,
  ILandingAgentsCreateDocumentResponse,
  ILandingAgentsDocumentsReadyQuery,
  ILandingAgentsGetINNRequest,
  ILandingAgentsRequestSMSRequest,
  ILandingAgentsUpdateLeadRequest,
  ILandingAgentsUploadFileResponse,
} from "services/agents-landing-service/types";
import { ICommonResponse } from "services/common-types";

export const agentsLandingApi = createApi({
  reducerPath: "agentsLandingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}api/LandingAgent`,
  }),
  endpoints: (builder) => ({
    getINN: builder.mutation<string, ILandingAgentsGetINNRequest>({
      query: (body) => ({
        url: "/GetINN",
        method: "POST",
        body,
      }),
    }),
    updateLead: builder.mutation<string, ILandingAgentsUpdateLeadRequest>({
      query: (body) => ({
        url: "/UpdateLeadAlt",
        method: "POST",
        body,
      }),
    }),
    getClientCheckResult: builder.query<
      ILandingAgentsClientCheckResultResponse,
      ILandingAgentsClientCheckResultQuery
    >({
      query: (params) => ({
        url: "/ClientCheckResult",
        method: "GET",
        params,
      }),
    }),
    createDocuments: builder.mutation<
      ILandingAgentsCreateDocumentResponse,
      ILandingAgentsCreateDocumentRequest
    >({
      query: (body) => ({
        url: "/CreateDocuments",
        method: "POST",
        body,
      }),
    }),
    getDocumentsReady: builder.query<string, ILandingAgentsDocumentsReadyQuery>(
      {
        query: (params) => ({
          url: "/DocumentsReady",
          method: "GET",
          params,
        }),
      }
    ),
    downloadDocuments: builder.query<
      string,
      ILandingAgentsDocumentDownloadQuery
    >({
      query: (params) => ({
        url: "/DocumentDownload",
        method: "GET",
        params,
      }),
    }),
    requestSMS: builder.mutation<
      ICommonResponse<null>,
      ILandingAgentsRequestSMSRequest
    >({
      query: (body) => ({
        url: "/RequestSMS",
        method: "POST",
        body,
      }),
    }),
    checkSMS: builder.mutation<
      ICommonResponse<null>,
      ILandingAgentsCheckSMSRequest
    >({
      query: (body) => ({
        url: "/CheckSMS",
        method: "POST",
        body,
      }),
    }),
    uploadFile: builder.mutation<
      ICommonResponse<ILandingAgentsUploadFileResponse>,
      File
    >({
      query: (body) => {
        const formData = new FormData();
        formData.append("file", body);
        return {
          url: "UploadFile",
          method: "POST",
          body: formData,
        };
      },
    }),
    getDocumentsSigned: builder.mutation<
      ILandingAgentDocumentSignedResponse,
      ILandingAgentDocumentSignedRequest
    >({
      query: (body) => {
        return {
          url: "DocumentsSignedNotificationAlt",
          method: "POST",
          body,
        };
      },
    }),
  }),
});
