import { useMemo, useCallback } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useToggle } from "ii-ui-kit";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { appSelectors } from "core/store/reducers/app-reducer";
import { authSelectors } from "core/store/reducers/auth-reducer";
import { crmActions } from "core/store/reducers/crm-reducer";
import {
  personalDataActions,
  personalDataSelectors,
} from "core/store/reducers/personal-data-reducer";
import { usePersonalDataPageInitialize } from "pages/personal-data/page-hooks";
import { IPersonalDataForm } from "pages/personal-data/types";
import { personalDataFormSchema } from "pages/personal-data/validation";
import { agentsLandingApi } from "services/agents-landing-service";
import { personalDataAdapter } from "services/agents-landing-service/adapters";

export const usePersonalDataPageState = () => {
  usePersonalDataPageInitialize();

  const defaultValues = useAppSelector(
    personalDataSelectors.selectPersonalData
  );

  const productType = useAppSelector(appSelectors.selectProductType);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const form = useForm<IPersonalDataForm>({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(personalDataFormSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    // Line required for errors subscription
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = form;

  const requirementsValues = watch([
    "isNotPublic",
    "isNotForeignCitizen",
    "isNotForeignResident",
    "isNotBornFromUSA",
    "isSelfInterest",
  ]);
  const showError = useMemo(() => {
    return !requirementsValues.every((val) => val);
  }, [requirementsValues]);

  const [showModal, toggleModal] = useToggle(false);
  const handleToggleModal = useCallback(() => {
    // using location insted of navigate to clear form (mainly dropzone)
    window.location.replace(AppRoutes.PersonalData);
  }, []);

  const [createLeadMutation, createLeadMutationState] =
    agentsLandingApi.useUpdateLeadMutation();
  const agentEmployeeID = useAppSelector(authSelectors.selectAgentEmployeeID);
  const handleCustomSubmit: SubmitHandler<IPersonalDataForm> = async (
    fields
  ) => {
    if (showError) {
      toggleModal();
      return;
    }

    dispatch(personalDataActions.setPersonalData(fields));

    const updateLeadRequestData = personalDataAdapter({
      personalData: fields,
      agentEmployeeID,
      productType,
    });

    const crmLeadID = await createLeadMutation(updateLeadRequestData).unwrap();

    dispatch(crmActions.setCrmLeadId(crmLeadID));

    navigate(AppRoutes.FundSelect);
  };

  return {
    showModal,
    handleToggleModal,
    handleSubmit,
    handleCustomSubmit,
    watch,
    form,
    setValue,
    register,
    createLeadMutationState,
  };
};
