import { createElement, Fragment, ReactNode } from "react";

import { RemoteData, pipe, remote } from "core/utils/fp";

export type RenderRemoteProps<A> = {
  data: RemoteData<A>;
  error?: ReactNode;
  initial?: ReactNode;
  pending?: ReactNode;
  pendingWithData?: (data: A) => ReactNode;
  success: (data: A) => ReactNode;
};

export const RenderRemote = <A>({
  data,
  pending = null,
  pendingWithData = () => pending,
  error = null,
  initial = null,
  success,
}: RenderRemoteProps<A>): JSX.Element =>
  createElement(
    Fragment,
    null,
    pipe(
      data,
      remote.fold(
        () => initial,
        (data) => (data ? pendingWithData(data) : pending),
        () => error,
        success
      )
    )
  );
