import React, { FC, ReactNode, useCallback } from "react";

import { Button, Modal, ModalProps } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ErrorImage } from "assets/error.svg";
import { AppRoutes } from "core/enums/routes";
import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";

import "./index.scss";

type Props = ModalProps & {
  errorTitle: string;
  errorText: string | ReactNode;
  onClose?: () => void;
};

const ErrorModal: FC<Props> = ({
  errorText,
  errorTitle,
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleExit = useCallback(() => {
    dispatch(appActions.clearStore);
    navigate(AppRoutes.PersonalData);
    onClose && onClose();
  }, [onClose, dispatch, navigate]);
  return (
    <Modal
      closable={false}
      onClose={handleExit}
      className="error-modal"
      {...props}
    >
      <div className="error-modal__body">
        <div className="error-modal__icon">
          <ErrorImage />
        </div>
        <h2 className="error-modal__title">Уважаемый агент!</h2>
        <div className="error-modal__content">
          <p className="error-modal__subtitle">{errorTitle}</p>
          <p className="error-modal__error-text">{errorText}</p>
        </div>
        <div className="error-modal__action">
          <Button onClick={handleExit}>Зарегистрировать нового клиента</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
