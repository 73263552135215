import { format, parse } from "date-fns";
import { ru } from "date-fns/locale";

import { DATE_FORMAT } from "core/enums/date-format";

export const DEFAULT_DATE_FORMAT = "dd.MM.yyyy";

/** Распарсить дату по формату */
export function parseDateString(value: string, format = DEFAULT_DATE_FORMAT) {
  const parsedDate = parse(value, format, new Date(), { locale: ru });

  return parsedDate;
}

export const parseSavedDateString = (value: string): string => {
  const date = new Date(value);
  return formatDate(date, DEFAULT_DATE_FORMAT);
};

export const formatDate = (date: Date | string, formatString: string) => {
  const value = typeof date === "string" ? new Date(Date.parse(date)) : date;
  return format(value, formatString, { locale: ru });
};

export const formatDateToLocaleRu = (date: Date | string) =>
  formatDate(date, DATE_FORMAT.FULL_DATE_WITH_DASH);

export const formatDateToLocaleRuFullDate = (date: Date | string) =>
  formatDate(date, DATE_FORMAT.FULL_DATE_FULL_MONTH);

export const formatDateRu = (date: Date | string, formatString: string) =>
  formatDate(date, formatString);

export const parseDateLocaleRu = (date: string) =>
  parse(date, DATE_FORMAT.FULL_DATE_WITH_DASH, new Date(), { locale: ru });

export const parseFullDateFullTime = (date: string) =>
  parse(date, DATE_FORMAT.FULL_DATE_AND_FULL_TIME, new Date(), {
    locale: ru,
  });

export const formatFullDateFullTimeWithSymbolHour = (value: string) => {
  const date = parseFullDateFullTime(value);
  return formatDate(date, DATE_FORMAT.FULL_DATE_AND_FULL_TIME);
};
