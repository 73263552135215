import { Button } from "ii-ui-kit";

import { INGOSINVEST_SITE } from "const";
import PageWrapper from "core/components/page-wrapper";
import ResultCard from "core/components/result-card";

import "./index.scss";

const ClientDocumentsSuccess = () => {
  return (
    <PageWrapper isDisplayClose={false}>
      <div className="client-documents-success">
        <ResultCard
          type="document-success"
          title="Спасибо!"
          text="Пакет документов успешно подписан и направлен вашему агенту для подписания с его стороны."
        />
        <div className="client-documents-success__button-wrapper">
          <Button
            className="client-documents-success__button"
            size="l"
            color="secondary"
            as="a"
            href={INGOSINVEST_SITE}
          >
            Закрыть
          </Button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ClientDocumentsSuccess;
