/**
 * Строковой шаблонизатор для маски инпута
 * Вызов с параметрами ('9 9 9  9 9 9', '123456') вернет '1 2 3  4 5 6'
 * @param template
 * @param value
 */
export const expandTemplateString = (template: string, value: string) => {
  let count = 0;
  return template.replace(/\d/g, (el) => {
    el = value[count];
    count += 1;
    return el;
  });
};

/**
 * Строковый шаблонизатор
 * Вызов с параметрами ('Hello, {user.name}!', {user: {name: 'Vasya'}})
 * сделает подстановку переменных и вернет "Hello, Vasya!"
 */
export const expandTemplateStringWithParams = <
  P extends Record<string, unknown>
>(
  template: string,
  params: P
): string => {
  const get = (obj: P, path: string): string | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = { ...obj };

    const arr = path.split(".");
    for (let i = 0; i < arr.length; i++) {
      if (result === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return result;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      result = result[arr[i]];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result;
  };

  return template.replace(/{([^}]+)}/g, (el, value) => {
    return get(params, value as string) as string;
  });
};
