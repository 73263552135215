import { Currency } from "core/enums/currency";
import { CurrencyCode } from "core/enums/currency-code";

const getPostfix = (postfix?: string) => {
  if (!postfix) {
    return "";
  }
  return postfix === "%" ? `${postfix}` : ` ${postfix}`;
};

export const getCurrency = (currencyName: string) => {
  switch (currencyName) {
    case CurrencyCode.Ruble: {
      return Currency.Ruble;
    }

    case CurrencyCode.Dollar: {
      return Currency.Dollar;
    }

    case CurrencyCode.Euro: {
      return Currency.Euro;
    }

    default: {
      return currencyName;
    }
  }
};

/**
 * Форматирует число в формат '*** ***,**'
 * @param value => число, которое форматируем
 * @param digits => кол-во чисел после запятой
 * @param postfix => постфикс, который установится после числа
 * @param hasPrefix => признак наличия символа вначале (+ или -)
 */

export const numberFormat = ({
  value,
  digits,
  postfix,
  hasPrefix,
}: {
  value: number | string | null;
  digits?: number;
  postfix?: string;
  hasPrefix?: boolean;
}) => {
  if (value === null) {
    return "";
  }
  const defaultDigits = 2;
  const currentDigits = digits ?? defaultDigits;
  const stringValue = String(value || 0)
    .trim()
    .replace(",", ".");
  const numberValue = Number(stringValue);
  const numberFormatter = Intl.NumberFormat("ru-RU", {
    maximumFractionDigits: currentDigits,
    minimumFractionDigits: currentDigits,
  });
  const prefix = hasPrefix && numberValue > 0 ? "+" : "";
  return `${prefix}${numberFormatter.format(numberValue)}${getPostfix(
    postfix
  )}`;
};

export const formatNumberToRubCurrency = (value: number) =>
  numberFormat({
    value,
    digits: 0,
    postfix: getCurrency("RUB"),
  });
