import { FC, useCallback, useState } from "react";

import { InputWithAction } from "ii-ui-kit";
import {
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
} from "react-hook-form";

import { handleNumberInput } from "core/utils/component-utils/handle-number-input";
import { parseDateString } from "core/utils/date-formatters";
import { getMeta } from "core/utils/form";
import { formatDateToIso } from "core/utils/format-date-to-iso";
import { IPersonalDataForm } from "pages/personal-data/types";
import { agentsLandingApi } from "services/agents-landing-service";

type InnSectionProps = {
  register: UseFormRegister<IPersonalDataForm>;
  form: UseFormReturn<IPersonalDataForm>;
  setValue: UseFormSetValue<IPersonalDataForm>;
};

const InnSection: FC<InnSectionProps> = ({ form, register, setValue }) => {
  const [error, setError] = useState<string>("");

  const [fetchINN] = agentsLandingApi.useGetINNMutation();

  const handleActionClick = useCallback(async () => {
    const {
      firstName,
      lastName,
      patronymic,
      birthDate,
      passportSeriesAndNumber: passport,
    } = form.getValues();

    if (!firstName || !lastName || !birthDate || !passport) {
      setError(
        'Поля "Фамилия", "Имя", "Дата рождения" и "Серия и номер паспорта" должны быть заполнены для автоматического заполнения ИНН'
      );
      return;
    }

    try {
      const formattedBirthDay = formatDateToIso(parseDateString(birthDate));

      const inn = await fetchINN({
        firstName,
        lastName,
        middleName: patronymic,
        birthDate: formattedBirthDay,
        passport,
      }).unwrap();
      setValue("inn", inn, { shouldValidate: true });
      setError("");
    } catch (e) {
      setError(
        "Ошибка автоматического заполнения. Попробуйте ввести ИНН вручную"
      );
    }
  }, [fetchINN, setValue, setError, form]);

  return (
    <div className="personal-data__section">
      <div className="personal-data__subtitle">ИНН</div>
      <InputWithAction
        addContainerClass="form__row"
        id="inn"
        label="ИНН"
        placeholder="ИНН"
        maxLength={12}
        inputMode="numeric"
        onInput={handleNumberInput}
        {...register("inn")}
        {...getMeta(form, "inn", error)}
        actionTitle="Заполнить автоматически"
        onActionClick={handleActionClick}
      />
    </div>
  );
};

export default InnSection;
