import { FC, useCallback, useEffect, useState } from "react";

import { formatISO } from "date-fns";
import { DownloadPanel } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import PageWrapper from "core/components/page-wrapper";
import { SignBySms } from "core/components/sign-button";
import { AppRoutes } from "core/enums/routes";
import { useSnackbar } from "core/store/reducers/app-reducer/hooks";
import {
  formatPhoneNumber,
  formatPhoneNumberWithoutSpacesAndBrackets,
} from "core/utils/phone-utils";
import pifFunds from "pages/fund-select/data/pif-funds";
import tmStrategies from "pages/fund-select/data/tm-strategies";
import {
  agentClientSignApi,
  IDocumentsListResponse,
} from "services/agent-client-sign";
import { agentsLandingApi } from "services/agents-landing-service";

import "./index.scss";

type ClientDocumentsProps = {
  documentsData: IDocumentsListResponse;
  linkId: string;
};

const ClientDocuments: FC<ClientDocumentsProps> = ({
  documentsData,
  linkId,
}) => {
  const { phone, fundId, documents } = documentsData;
  const productsData = [...pifFunds, ...tmStrategies];
  const fund = productsData.find((product) => product.shareId === fundId);

  const [clientSignedInfo, setClientSignedInfo] = useState({
    signedDate: "",
    code: "",
  });

  const { actions: snackbarActions } = useSnackbar();

  const [sendClientSignCodeMutation, sendSmsMutationState] =
    agentsLandingApi.useRequestSMSMutation();
  const [verifyClientSignCodeMutation] = agentsLandingApi.useCheckSMSMutation();
  const [documentSign, documentSignMutationState] =
    agentClientSignApi.useDocumentSignMutation();

  const handleClientSign = useCallback(async () => {
    await sendClientSignCodeMutation({
      phone: formatPhoneNumberWithoutSpacesAndBrackets(phone),
      codeLength: 6,
    }).unwrap();
  }, [sendClientSignCodeMutation, phone]);

  const handleClientSignError = () => {
    snackbarActions.setSnackbarAlert({
      type: "danger",
      title: "Произошла непредвиденная ошибка",
      text: "Попробуйте совершить действие ещё раз или свяжитесь со своим агентом",
    });
  };

  const handleClientValidate = useCallback(
    async (code: string) => {
      try {
        await verifyClientSignCodeMutation({
          phone: formatPhoneNumberWithoutSpacesAndBrackets(phone),
          code,
        }).unwrap();

        setClientSignedInfo({ code, signedDate: formatISO(new Date()) });

        return true;
      } catch (e) {
        return false;
      }
    },
    [phone, verifyClientSignCodeMutation]
  );

  const navigate = useNavigate();

  const handleSuccesSign = useCallback(async () => {
    await documentSign({
      clientCode: clientSignedInfo.code,
      verifyTime: clientSignedInfo.signedDate,
      docId: documentsData.documents[0].id,
      linkId,
    });

    navigate(AppRoutes.ClientDocumentsSignSuccess);
  }, [
    clientSignedInfo.code,
    clientSignedInfo.signedDate,
    documentSign,
    documentsData.documents,
    linkId,
    navigate,
  ]);

  useEffect(() => {
    clientSignedInfo.code && handleSuccesSign();
  }, [clientSignedInfo.code, handleSuccesSign]);

  return (
    <PageWrapper isDisplayClose={false} title="Подписание документов">
      <div className="client-documents">
        <p className="client-documents__description">
          Вам необходимо ознакомиться и подписать электронно следующие
          документы:
        </p>
        {documents.map((doc) => (
          <DownloadPanel
            key={`document-download-${doc.id}`}
            text={`Комплект документов для ${fund?.shortName}`}
            href={`/api/LandingAgent/DocumentDownload?documentGUID=${doc.id}`}
            className="client-documents__doc"
          />
        ))}
        <SignBySms
          className="client-documents__action"
          isSuccessSign={false}
          title="Подписать"
          color="primary"
          sentTo={formatPhoneNumber(phone)}
          onSignClick={handleClientSign}
          onErrorSign={handleClientSignError}
          validate={handleClientValidate}
          isLoading={
            sendSmsMutationState.isLoading ||
            documentSignMutationState.isLoading
          }
        />
      </div>
    </PageWrapper>
  );
};

export default ClientDocuments;
