import AuthLogo from "core/components/auth-logo";

import { PhoneModal } from "./components/phone-modal";
import { useAuthPageState } from "./state";

import "./index.scss";

const AuthPage = () => {
  useAuthPageState();

  return (
    <div className="auth">
      <AuthLogo />
      <PhoneModal />
    </div>
  );
};

export default AuthPage;
