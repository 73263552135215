import { useCallback } from "react";

import { useToggle } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";
import { usePaymentPageInitialize } from "pages/payment-page/page-hooks";

export const usePaymentErrorPageState = () => {
  usePaymentPageInitialize();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isVisibleExitModal, toggleExitModal] = useToggle(false);

  const handleBackwards = useCallback(() => {
    navigate(AppRoutes.Payment);
  }, [navigate]);

  const handleExit = useCallback(() => {
    dispatch(appActions.clearStore());
  }, [dispatch]);

  return {
    isVisibleExitModal,
    toggleExitModal,
    handleBackwards,
    handleExit,
  };
};
