import { FC } from "react";

import classNames from "classnames";
import "./index.scss";

export interface IBadgeProps {
  className?: string;
  content: string;
}

export const Badge: FC<IBadgeProps> = ({ className, content }) => (
  <div className={classNames("badge", className)}>
    <div className="badge__content">{content}</div>
  </div>
);
