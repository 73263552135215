/* eslint-disable @typescript-eslint/no-unused-vars */
import { DATE_FORMAT } from "core/enums/date-format";
import { ProductType } from "core/types/entities/product-type";
import { formatDate, parseDateString } from "core/utils/date-formatters";
import { IPersonalDataForm } from "pages/personal-data/types";
import { ILandingAgentsUpdateLeadRequest } from "services/agents-landing-service/types";

import { getProductCategory } from "../utils";

interface IPersonalDataAdapter {
  (args: {
    personalData: IPersonalDataForm;
    agentEmployeeID: string;
    productType: ProductType;
  }): ILandingAgentsUpdateLeadRequest;
}

export const personalDataAdapter: IPersonalDataAdapter = ({
  personalData,
  agentEmployeeID,
  productType,
}) => {
  const acceptanceTimeStamp = new Date().toISOString();
  const {
    isNotPublic,
    isNotForeignCitizen,
    isNotForeignResident,
    isRegisterAddressSameAsLiveAddress,
    isNotBornFromUSA,
    isSelfInterest,
    patronymic,
    passportSeriesAndNumber,
    passportIssuedById: issueId,
    passportIssuedBy: issuedBy,
    passportIssueDate: issueDate,
    birthDate,
    acceptEDM,
    ...client
  } = personalData;

  const [series, number] = passportSeriesAndNumber.split(" ");

  return {
    leadDescription: {
      crmLeadID: "",
      cjmEvent: "FIN_LP",
      gosuslugiOID: "",
      acceptPrivateDataProcessing: 1,
      acceptSMS: 1,
      commerceURL: "",
      products: [],
      productCategory: getProductCategory(productType),
      acceptEDM,
      acceptanceTimeStamp,
      agentEmployeeID,
    },
    client: {
      ...client,
      isNotBornFromUSA: Number(isNotBornFromUSA),
      isSelfInterest: Number(isSelfInterest),
      isPublic: Number(!isNotPublic),
      middleName: patronymic,
      isResident: 1,
      birthDate: formatDate(
        parseDateString(birthDate),
        DATE_FORMAT.FULL_DATE_API
      ),
      personDocument: {
        code: "21",
        series,
        number,
        issueDate: formatDate(
          parseDateString(issueDate),
          DATE_FORMAT.FULL_DATE_API
        ),
        issuedBy,
        issueId,
        validity: "",
        validUntil: null,
      },
      snils: "",
      birthCountry: "Россия",
      citizenships: "РФ",
      residentCountry: "643",
      ids: null,
      bankAccounts: [],
      competitor: null,
      liveAddress: isRegisterAddressSameAsLiveAddress
        ? client.registerAddress
        : client.liveAddress,
      postAddress: null,
    },
  };
};
