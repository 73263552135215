import { Reducers } from "core/enums/reducers";
import { RootState } from "core/store";

const selectCrmInfo = (state: RootState) => state[Reducers.Crm];
const selectCrmLeadId = (state: RootState) => state[Reducers.Crm].crmLeadId;
const selectDockPackId = (state: RootState) => state[Reducers.Crm].dockPackId;

export const crmSelectors = {
  selectCrmInfo,
  selectCrmLeadId,
  selectDockPackId,
};
