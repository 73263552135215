import { FC } from "react";

import { Button, ButtonProps, Loader } from "ii-ui-kit";

import { SuccessIcon } from "core/components/svg";

type Props = Pick<ButtonProps, "color" | "disabled"> & {
  isSuccessSign: boolean;
  isLoading?: boolean;
  title: string;
  className?: string;
  onClickSign: () => void;
};

export const ClientSignButton: FC<Props> = ({
  color = "secondary",
  isSuccessSign,
  isLoading,
  onClickSign,
  title,
  disabled,
  className,
}) => {
  const renderLeftContent = () => {
    if (isLoading) {
      return () => <Loader />;
    }

    if (isSuccessSign) {
      return () => <SuccessIcon />;
    }

    return undefined;
  };

  return (
    <Button
      disabled={disabled || isSuccessSign || isLoading}
      className={className}
      renderLeftContent={renderLeftContent()}
      onClick={onClickSign}
      color={color}
      size="l"
    >
      {title}
    </Button>
  );
};
