import { FC, useState } from "react";

import { MenuItemModel } from "ii-ui-kit";

import Select from "core/components/select";
import { useAppSelector } from "core/store/hooks";
import { authSelectors } from "core/store/reducers/auth-reducer";
import { paymentSelectors } from "core/store/reducers/payment-reducer";
import { BANK_BIK_VALUE, IPayerInfoModel } from "pages/payment-page/types";
import { IProductPropsModel } from "services/products-service/types";

import Requisites from "./requisites";

type RequisitesSelectionProps = {
  props: IProductPropsModel[];
  amountMoney: number;
  payerInfo: IPayerInfoModel;
};

const RequisitesSelection: FC<RequisitesSelectionProps> = ({
  props,
  amountMoney,
  payerInfo,
}) => {
  const isAgentSoyuzBank = useAppSelector(authSelectors.selectIsAgentSoyuzBank);

  const bankItems: MenuItemModel<string>[] = props.map((item) => ({
    label: item.bankName,
    value: item.biс,
  }));

  const defaultBank = (isAgentSoyuzBank &&
    bankItems.find((item) => item.value === BANK_BIK_VALUE.SOYUZ)) || {
    value: "",
    label: "",
  };

  const { requisitesPurpose } = useAppSelector(
    paymentSelectors.selectPaymentPurpose
  );

  const [selectedBank, setSelectedBank] =
    useState<MenuItemModel<string>>(defaultBank);

  const selectedBankInfo = props.find(
    (item) => item.biс === selectedBank.value
  );

  return (
    <>
      <Select
        label="Банк"
        onSelect={(value) => {
          setSelectedBank(value);
        }}
        value={selectedBank}
        items={bankItems}
      />

      {selectedBank.value && selectedBankInfo && (
        <Requisites
          amountMoney={amountMoney}
          payerInfo={payerInfo}
          paymentPurpose={requisitesPurpose}
          bank={{
            name: selectedBankInfo.bankName,
            label: selectedBankInfo.bankName,
            requisites: {
              checkingAccount: selectedBankInfo.paymentAccount,
              corrAccount: selectedBankInfo.corrPaymentAccount,
              bik: selectedBankInfo.biс,
            },
          }}
          receiverInfo={{
            name: selectedBankInfo.payee,
            inn: selectedBankInfo.inn,
            kpp: selectedBankInfo.kpp,
          }}
        />
      )}
    </>
  );
};

export default RequisitesSelection;
