import React, { FC } from "react";

import { Button, Modal } from "ii-ui-kit";
import "./exit-modal.scss";

type ExitModalProps = {
  shown: boolean;
  onClose: () => void;
  onExit: () => void;
};

export const ExitModal: FC<ExitModalProps> = ({ shown, onClose, onExit }) => {
  return (
    <Modal className="exit-modal" shown={shown} onClose={onClose}>
      <div className="exit-modal__header">Хотите выйти?</div>
      <div className="exit-modal__text">
        Все несохраненные данные будут потеряны
      </div>
      <div className="exit-modal__footer">
        <Button size="l" color="primary" onClick={onClose}>
          Отмена
        </Button>
        <Button
          size="l"
          className="exit-modal__exit-btn"
          color="secondary"
          onClick={onExit}
        >
          Выйти
        </Button>
      </div>
    </Modal>
  );
};
