import { FormEventHandler } from "react";

/** Обработка инпута где можно вводить только цифры */
export const handleNumberInput: FormEventHandler<HTMLInputElement> = (
  event
) => {
  const checkedValue = event.currentTarget.value;

  event.currentTarget.value = checkedValue.replace(/\D/g, "");
};
