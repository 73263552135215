import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Reducers } from "core/enums/reducers";
import { appActions } from "core/store/reducers/app-reducer";
import ICrmState from "core/store/reducers/crm-reducer/types";

const initialState: ICrmState = {
  crmLeadId: "",
  dockPackId: "",
};

export const crmSlice = createSlice({
  name: Reducers.Crm,
  initialState,
  reducers: {
    setCrmLeadId: (state, { payload }: PayloadAction<string>) => {
      state.crmLeadId = payload;
    },
    setDockPackId: (state, { payload }: PayloadAction<string>) => {
      state.dockPackId = payload;
    },
  },
  extraReducers: {
    [appActions.clearStore.type]: () => {
      return initialState;
    },
  },
});

export const crmActions = crmSlice.actions;

export const crmReducer = crmSlice.reducer;
