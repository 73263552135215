export type QrCodePaymentData = {
  /** Расчетный счет банка */
  bankCheckingAccount: string;
  /** Имя банка */
  bankName: string;
  /** БИК банка */
  bankBik: string;
  /** Корр. счет банка */
  bankCorrAccount: string;
  /** Имя получателя */
  receiverName: string;
  /** ИНН получателя */
  receiverInn: string;
  /** КПП получателя */
  receiverKpp: string;
  /** Цель платежа */
  paymentPurpose: string;
  /** Количество денег */
  amountMoney: number | string;
  /** Фамилия плательщика */
  payerLastName: string;
  /** Отчество плательщика */
  payerPatronymic: string;
  /** Имя плательщика */
  payerFirstName: string;
};

/** Получить значение для qr кода для платежа */
export const getQrCodePaymentValue = (data: QrCodePaymentData) => {
  const {
    receiverInn,
    receiverKpp,
    receiverName,
    bankBik,
    bankName,
    bankCheckingAccount,
    bankCorrAccount,
    paymentPurpose,
    amountMoney,
    payerLastName,
    payerPatronymic,
    payerFirstName,
  } = data;

  const qrCodeValue = `ST00012|Name=${receiverName}|PersonalAcc=${bankCheckingAccount}|BankName=${bankName}|BIC=${bankBik}|CorrespAcc=${bankCorrAccount}|PayeeINN=${receiverInn}|KPP=${receiverKpp}|LastName=${payerLastName}|FirstName=${payerFirstName}|MiddleName=${payerPatronymic}|Purpose=${paymentPurpose}|Sum=${
    Number(amountMoney) * 100
  }`;

  return qrCodeValue;
};
