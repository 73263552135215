import { IFund } from "core/types/entities/fund";
import { ProductType } from "core/types/entities/product-type";
import pifFunds from "pages/fund-select/data/pif-funds";

import tmStrategies from "./tm-strategies";

const pifFundsIds = [17590, 541872, 17586, 17587 /** 639383 */];
const iisFundsIds = [43338179, 43338183, 43338181];
const duFundsIds = [39353077, 43338144, 44150655, 48698480];

export const productTypeToFundsIdsMap: Record<ProductType, number[]> = {
  [ProductType.PIF]: pifFundsIds,
  [ProductType.DU]: duFundsIds,
  [ProductType.IIS]: iisFundsIds,
};

export const productTypeToFundsData: Record<ProductType, IFund[]> = {
  [ProductType.PIF]: pifFunds,
  [ProductType.DU]: tmStrategies,
  [ProductType.IIS]: [],
};
