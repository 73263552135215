import STEP_KEYS from "core/enums/steps";
import { StepItem } from "core/types/app/step-item";

export const STEPS: StepItem[] = [
  {
    key: STEP_KEYS.PERSONAL_DATA,
    title: "Персональные данные",
    isActive: true,
    isPassed: false,
  },
  {
    key: STEP_KEYS.FUND_SELECT,
    title: "Выбор продукта",
    isActive: false,
    isPassed: false,
  },
  {
    key: STEP_KEYS.DOCUMENTS,
    title: "Подписание документов",
    isActive: false,
    isPassed: false,
  },
  {
    key: STEP_KEYS.PAYMENT,
    title: "Оплата",
    isActive: false,
    isPassed: false,
  },
];
