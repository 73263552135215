import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import { BASE_API_URL } from "const";
import { authActions } from "core/store/reducers/auth-reducer/reducer";
import { ICommonResponse } from "services/common-types";

import {
  IAgentInfoRequest,
  IAgentInfoResponse,
  ISendAuthCodeRequest,
  IVerifyAuthCodeRequest,
  IVerifyAuthCodeResponse,
} from "./types";

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}api/Agent`,
});

const baseQueryWithSetAgentID: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (api.endpoint.includes("verifyAuthSms")) {
    if (result.data) {
      const body = (result.data as ICommonResponse<IVerifyAuthCodeResponse>)
        .body;

      api.dispatch(authActions.setAgentEmployeeID(body.agentEmployeeID));
    }
  }

  return result;
};

export const authApi = createApi({
  reducerPath: "authApi",
  keepUnusedDataFor: 0,
  baseQuery: baseQueryWithSetAgentID,
  endpoints: (builder) => ({
    sendAuthSms: builder.mutation<
      ICommonResponse<unknown>,
      ISendAuthCodeRequest
    >({
      query: (req) => ({
        url: "/SendAuthCode",
        method: "POST",
        body: req,
      }),
    }),

    verifyAuthSms: builder.mutation<
      ICommonResponse<IVerifyAuthCodeResponse>,
      IVerifyAuthCodeRequest
    >({
      query: (req) => ({
        url: "/VerifyAuthCode",
        method: "POST",
        body: req,
      }),
    }),

    agentInfo: builder.mutation<
      ICommonResponse<IAgentInfoResponse>,
      IAgentInfoRequest
    >({
      query: (req) => ({
        url: "/AgentInfo",
        method: "POST",
        body: req,
      }),
    }),
  }),
});
