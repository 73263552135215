import classNames from "classnames";
import { Button, Input, MenuItemModel } from "ii-ui-kit";

import Autocomplete from "core/components/autocomplete";
import PageWrapper from "core/components/page-wrapper";
import Select from "core/components/select";
import { withCurrencyInputRulesHoc } from "core/hocs/with-currency-input-rules-hoc";
import { ProductType } from "core/types/entities/product-type";
import { getMeta } from "core/utils/form";

import ClientCheckErrorModal from "./components/client-check-error-modal";
import { useFundSelectPageState } from "./state";
import { ClientCheckStatus } from "./types";
import { getAmountBoundariesText } from "./utils";

import "./index.scss";

const CurrencyInput = withCurrencyInputRulesHoc(Input);

const FundSelect = () => {
  const {
    productType,
    personalAccount,
    clientCheckStatus,
    handleSubmit,
    handleCustomSubmit,
    selectedFundOption,
    fundsOptions,
    formState,
    register,
    form,
    bankSuggestions,
    setValue,
    createDocumentsMutationState,
    boundaries,
    funds,
    pageContent,
  } = useFundSelectPageState();

  const estimateAdditionalText =
    productType !== ProductType.PIF
      ? getAmountBoundariesText({
          min: boundaries.min,
          max: boundaries.max,
        })
      : undefined;

  const handleSelectFund = ({ value }: MenuItemModel<number>) => {
    setValue(
      "selectedFund",
      funds.find((fund) => fund.shareId === value)
    );
    form.clearErrors("selectedFund");
  };

  return (
    <PageWrapper title={pageContent.title}>
      <ClientCheckErrorModal
        shown={clientCheckStatus === ClientCheckStatus.Failed}
      />
      <form onSubmit={handleSubmit(handleCustomSubmit)} className="fund-select">
        <Select
          label={pageContent.productLabel}
          onSelect={handleSelectFund}
          value={selectedFundOption}
          items={fundsOptions}
          error={formState.errors.selectedFund?.shareId?.message}
        />
        <CurrencyInput
          id="estimate"
          label={pageContent.amountLabel}
          addContainerClass={classNames({
            "fund-select__estimate": Boolean(estimateAdditionalText),
          })}
          placeholder={pageContent.amountLabel}
          additionalText={estimateAdditionalText}
          {...register("estimate")}
          {...getMeta(form, "estimate")}
        />
        {productType === ProductType.PIF && (
          <>
            <Autocomplete
              options={bankSuggestions}
              onOptionClick={({
                value: bankName,
                data: {
                  bic: bik,
                  address: { value: address },
                  inn,
                  correspondent_account: corrAccount,
                },
              }) =>
                setValue("bankAccount", {
                  bankName,
                  bik,
                  inn,
                  address,
                  corrAccount,
                })
              }
              optionRender={({ value, data: { bic } }) => `${bic} (${value})`}
              componentProps={{
                inputProps: {
                  id: "bankAccount.bankName",
                  label: "Наименование банка или БИК",
                  placeholder: "Наименование банка или БИК",
                  ...register("bankAccount.bankName"),
                  ...getMeta(form, "bankAccount.bankName"),
                },
              }}
            />

            <Input
              value={personalAccount}
              id="paymentAccount"
              label="Номер расчетного счета"
              placeholder="Номер расчетного счета"
              mask="40899999999999999999"
              {...register("paymentAccount")}
              {...getMeta(form, "paymentAccount")}
            />
          </>
        )}
        <div className="fund-select__button-wrapper">
          <Button
            isLoading={
              clientCheckStatus === ClientCheckStatus.Pending ||
              createDocumentsMutationState.isLoading
            }
            disabled={clientCheckStatus !== ClientCheckStatus.Success}
            size="l"
            className="fund-select__button"
          >
            Продолжить
          </Button>
        </div>
      </form>
    </PageWrapper>
  );
};

export default FundSelect;
