import React, { FC } from "react";

import { ModalProps } from "ii-ui-kit";

import ErrorModal from "core/components/error-modal";

type Props = Pick<ModalProps, "shown">;

const ClientCheckErrorModal: FC<Props> = ({ shown }) => {
  return (
    <ErrorModal
      errorTitle="К сожалению, по вашим анкетным данным заключение договора онлайн невозможно."
      errorText={
        <>
          <p>
            Наша система не смогла зарегистрировать клиента по параметрам
            безопасности.
          </p>
          <p>
            Данные клиента необходимо передать вашему менеджеру, чтобы
            сотрудники УК ИИ в индивидуальном порядке связались с ним.
          </p>
        </>
      }
      shown={shown}
    />
  );
};

export default ClientCheckErrorModal;
