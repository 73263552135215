import { Outlet } from "react-router-dom";

import { Header } from "../header/header";
import "./index.scss";

export type MainLayoutProps = {
  showSteps?: boolean;
};

const MainLayout = ({ showSteps }: MainLayoutProps) => {
  return (
    <div className="layout">
      <Header showSteps={showSteps} addClass="layout__header" />
      <div className="layout__content">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
