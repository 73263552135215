import { Nullable } from "core/types/utils/nullable";

export interface IPassport {
  code: string;
  series: string;
  number: string;
  issueDate: string;
  issuedBy: string;
  issueId: string;
  validity: string;
  validUntil: Nullable<string>;
}

export enum PassportPage {
  Main = "1",
  Secondary = "2",
}
