import React, { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";
import { ProductType } from "core/types/entities/product-type";

import ProductForm from "./components/product-form";

const ProductSelect = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleProductSelect = useCallback(
    (productType: ProductType) => {
      dispatch(appActions.setProductType(productType));
      navigate(AppRoutes.PersonalData);
    },
    [dispatch, navigate]
  );
  return (
    <div className="product-select">
      <ProductForm onProductSelect={handleProductSelect} />
    </div>
  );
};

export default ProductSelect;
