import { FC } from "react";

import { Loader } from "ii-ui-kit";

import { RenderRemote } from "core/components/render-remote";
import { useAppSelector } from "core/store/hooks";
import { paymentSelectors } from "core/store/reducers/payment-reducer";
import { RemoteData, pipe } from "core/utils/fp";
import { IPayerInfoModel } from "pages/payment-page/types";
import { productsApi } from "services/products-service";
import { IProductPropsResponse } from "services/products-service/types";

import RequisitesSelection from "./requisites-selection";

type RequisitesContainerProps = {
  amountMoney: number;
  payerInfo: IPayerInfoModel;
};

export const RequisitesContainer: FC<RequisitesContainerProps> = ({
  amountMoney,
  payerInfo,
}) => {
  const { fundId } = useAppSelector(paymentSelectors.selectPaymentInfo);

  const productProps: RemoteData<IProductPropsResponse> =
    productsApi.useGetProductPropsQuery(fundId);

  const data = pipe(productProps);

  return (
    <RenderRemote
      data={data}
      pending={<Loader />}
      success={(data) => (
        <RequisitesSelection
          props={data.body.props}
          amountMoney={amountMoney}
          payerInfo={payerInfo}
        />
      )}
    />
  );
};
