import { Modal, SMSBlock } from "ii-ui-kit";

import AuthLogo from "core/components/auth-logo";

import { useSmsPageState } from "./state";

import "./index.scss";

const SmsPage = () => {
  const { isMobile, agentPhone, handleValidate, countdown, handleResendSms } =
    useSmsPageState();

  return (
    <div className="sms">
      {!isMobile && <AuthLogo />}

      <Modal className="sms__modal" shown swipeable closable={false}>
        <SMSBlock
          sentTo={`+${agentPhone}`}
          codeLength={6}
          validate={handleValidate}
          countdown={countdown}
          onResend={handleResendSms}
        />
      </Modal>
    </div>
  );
};

export default SmsPage;
