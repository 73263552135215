import { useCountdown, useBreakpoint, useExhaustiveEffect } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { ROUNDS_TIMEOUT_MAP } from "core/const/rounds-timeout-map";
import { AppRoutes } from "core/enums/routes";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { authSelectors, authActions } from "core/store/reducers/auth-reducer";
import { authApi } from "services/auth-service";

export const useSmsPageState = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const countdown = useCountdown();
  const isMobile = useBreakpoint((builder) => builder.under("md"));
  const timeout = ROUNDS_TIMEOUT_MAP.get(countdown.roundsCount) || "60s";
  const agentPhone = useAppSelector(authSelectors.selectAgentPhone);

  const [verifyAuthSms] = authApi.useVerifyAuthSmsMutation();
  const [triggerAgentInfo] = authApi.useAgentInfoMutation();
  const [triggerSendAuthSms] = authApi.useSendAuthSmsMutation();

  const handleResendSms = async () => {
    await triggerSendAuthSms({ mobile: `+${agentPhone}` }).unwrap();
  };

  useExhaustiveEffect(() => {
    if (countdown.roundsCount === 0) {
      countdown.start(timeout);
    }
  }, []);

  const handleValidate = async (code: string) => {
    const result = await verifyAuthSms({
      mobile: `+${agentPhone}`,
      code,
    });

    if ("error" in result) {
      return false;
    }

    const agentInfo = await triggerAgentInfo({
      agentEmployeeID: result.data.body.agentEmployeeID,
    });

    // TODO в данном случае нужно выводить другое сообщение отличное от ui-kit (доработать кит)
    // или делать всплывающую ошибку
    // сейчас выводится, что код не верный, хотя ошибка в методе подтягивания данных, а не валидации кода
    if ("error" in agentInfo) {
      return false;
    }

    dispatch(authActions.setAgentInfo(agentInfo.data.body));

    navigate(AppRoutes.ProductSelect);

    return true;
  };

  return {
    isMobile,
    agentPhone,
    handleValidate,
    countdown,
    handleResendSms,
  };
};
