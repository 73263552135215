export interface IMerchantQrcDataRequest {
  account: string;
  amount: string;
  callbackMerchantNotifications: string;
  currency: string;
  merchantId: string;
  paymentPurpose: string;
  qrcType: string;
  templateVersion: string;
}

export interface IMerchantQrcDataResponse {
  code: string;
  message: string;
  transactionId: string;
  data: {
    qrcId: string;
    payload: string;
    status: string;
  };
}

export interface IMerchantFrontDataRequest {
  qrcId: string;
  crmApplicationNumber: string;
  crmLeadId: string;
  selfPayment: boolean;
}

export interface IMerchantQrcStatusRequest {
  qrcIds: string[];
}

export enum MerchantQrcStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  RECEIVED = "RECEIVED",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface IMerchantQrcStatusResponse {
  status: MerchantQrcStatus;
}

export interface IMerchantRequestCallbackRequest {
  qrcId: string;
}

export interface IMerchantRequestCallbackResponse {
  code: string;
  qrcId: string;
  message: MerchantQrcStatus;
}
