export enum AppRoutes {
  Main = "/",
  Auth = "/auth",
  Sms = "/auth/sms",
  ProductSelect = "/product-select",
  PersonalData = "/personal-data",
  FundSelect = "/fund-select",
  Documents = "/documents",
  DocumentsSuccess = "/documents-success",
  Payment = "/payment",
  PaymentSuccess = "/payment-success",
  PaymentError = "/payment-error",
  ClientDocumentsSign = "/client-documents",
  ClientDocumentsSignSuccess = "/client-documents-signed",
}
