import { RootState } from "core/store";

const selectAgentPhone = (state: RootState) => state.auth.agentLoginPhone;
const selectAgentEmployeeID = (state: RootState) => state.auth.agentEmployeeID;
const selectAgentInfo = (state: RootState) => state.auth.agentInfo;
const selectAgentAuthorized = (state: RootState) =>
  Boolean(state.auth.agentEmployeeID);
/** Проверить относится ли агент к банку союз */
const selectIsAgentSoyuzBank = (state: RootState) =>
  state.auth.agentInfo?.agent?.code === "02";

export const authSelectors = {
  selectAgentPhone,
  selectAgentEmployeeID,
  selectAgentInfo,
  selectAgentAuthorized,
  selectIsAgentSoyuzBank,
};
