import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { Button, Hint, Input } from "ii-ui-kit";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppDispatch } from "core/store/hooks";
import { authActions } from "core/store/reducers/auth-reducer/reducer";
import { isFetchBaseQueryError } from "core/utils/api-error-utils";
import { authFormSchema } from "pages/auth-page/validation";
import { authApi } from "services/auth-service";
import { ICommonResponse } from "services/common-types";

import { IPhoneFormProps, IPhoneFormFields } from "./types";

import "./index.scss";

export const PhoneForm: React.FC<IPhoneFormProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sendAuthSms, mutationState] = authApi.useSendAuthSmsMutation();
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPhoneFormFields>({
    mode: "onSubmit",
    resolver: yupResolver(authFormSchema),
  });

  const onSubmit: SubmitHandler<IPhoneFormFields> = async (data) => {
    const preparedData = {
      ...data,
      phone: data.phone.replace(/\D/g, ""),
    };

    dispatch(authActions.setAgentPhone(preparedData.phone));

    const result = await sendAuthSms({ mobile: `+${preparedData.phone}` });

    if ("error" in result && isFetchBaseQueryError(result.error)) {
      const data = result.error.data as ICommonResponse<unknown>;

      setError(
        data?.exception.description || "Произошла ошибка. Попробуйте позже"
      );
      return;
    }

    navigate(AppRoutes.Sms);
  };

  return (
    <form
      className={classNames(className, "phone-form")}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Hint
        className="phone-form__error"
        isOpen={Boolean(error)}
        text={error ?? ""}
        style="error"
      />

      <Input
        addContainerClass="phone-form__phone-input"
        id="phoneNumber"
        label="Номер телефона"
        placeholder="Номер телефона"
        inputMode="tel"
        // eslint-disable-next-line no-nonoctal-decimal-escape
        mask="+7 (\999)-999-99-99"
        autoFocus={true}
        form="formField"
        errorText={errors["phone"]?.message}
        validationStatus={errors["phone"] ? "error" : undefined}
        {...register("phone")}
      />
      <Button
        isLoading={mutationState.isLoading}
        className="phone-form__button"
        title="Войти"
        size="l"
      />
    </form>
  );
};
