import { IFundSelectForm } from "core/store/reducers/fund-select-reducer/types";
import { ILandingAgentsCreateDocumentRequest } from "services/agents-landing-service/types";

interface IFundSelectAdapter {
  (args: {
    fundSelectData: IFundSelectForm;
    crmLeadId: string;
    fullName: string;
  }): ILandingAgentsCreateDocumentRequest;
}

export const fundSelectAdapter: IFundSelectAdapter = ({
  fundSelectData,
  crmLeadId,
  fullName,
}) => {
  return {
    crmLeadId,
    productCode: String(fundSelectData.selectedFund?.shareId),
    estimate: Number.parseInt(fundSelectData.estimate),
    bankAccount: {
      ...fundSelectData.bankAccount,
      paymentAccount: fundSelectData.paymentAccount,
      payee: fullName,
    },
    brokerAccount: null,
  };
};
