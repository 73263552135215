import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Reducers } from "core/enums/reducers";
import STEP_KEYS from "core/enums/steps";
import { RootState } from "core/store";
import { ProductType } from "core/types/entities/product-type";

import { IAppState, SnackbarPosition, SnackbarState } from "./types";

const initialState: IAppState = {
  productType: ProductType.PIF,
  currentStep: STEP_KEYS.PRODUCT_SELECT,
  isLoading: true,
  snackbar: {
    position: "bottom",
    title: "Что-то пошло не так",
    type: "error",
    text: "Пожалуйста, попробуйте еще раз",
    isOpen: false,
    buttonText: "",
    onButtonClick: undefined,
  },
};

const appSlice = createSlice({
  name: Reducers.App,
  initialState,
  reducers: {
    setProductType: (state, { payload }: PayloadAction<ProductType>) => {
      state.productType = payload;
    },
    setCurrentStep: (state, { payload }: PayloadAction<STEP_KEYS>) => {
      state.currentStep = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setSnackbarAlert: (state, { payload }: PayloadAction<SnackbarState>) => {
      state.snackbar = { ...state.snackbar, ...payload };
    },
    setSnackbarPosition: (
      state,
      { payload }: PayloadAction<SnackbarPosition>
    ) => {
      state.snackbar.position = payload;
    },
    clearSnackbarAlert: (state) => {
      state.snackbar = initialState.snackbar;
    },
    clearStore: (state) => {
      state = { ...initialState, isLoading: state.isLoading };
      return state;
    },
  },
});

const selectProductType = (state: RootState) => state.app.productType;
const selectCurrentStep = (state: RootState) => state.app.currentStep;
const selectIsLoading = (state: RootState) => state.app.isLoading;
const selectSnackbarState = (state: RootState) => state.app.snackbar;

export const appSelectors = {
  selectProductType,
  selectCurrentStep,
  selectIsLoading,
  selectSnackbarState,
};

export const appActions = appSlice.actions;

export default appSlice.reducer;
