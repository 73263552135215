import React, { ReactNode } from "react";

import classNames from "classnames";

import { ReactComponent as ErrorIcon } from "assets/icons/error-icon.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/success-icon.svg";
import { ReactComponent as SuccessDocumentIcon } from "assets/icons/success.svg";
import { ReactComponent as WaitingIcon } from "assets/icons/waiting-icon.svg";

import "./index.scss";

export type ResultCardProps = {
  type: "success" | "error" | "waiting" | "document-success";
  title: string;
  text?: string | ReactNode;
};

const ResultCard = ({ type, title, text }: ResultCardProps) => {
  const renderIcon = () => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      case "waiting":
        return <WaitingIcon />;
      case "document-success":
        return <SuccessDocumentIcon />;
    }
  };

  const classes = classNames("result-card", {
    "result-card--payment": type !== "document-success",
  });

  return (
    <div className={classes}>
      <div className="result-card__icon">{renderIcon()}</div>
      <p className="result-card__title">{title}</p>
      {text && <p className="result-card__text">{text}</p>}
    </div>
  );
};

export default ResultCard;
