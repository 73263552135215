const pifFunds = [
  {
    name: "ОПИФ рыночных финансовых инструментов «Моё будущее»",
    shortName: "ОПИФ РФИ «Моё будущее»",
    qrName: "ОПИФ Моё будущее",
    available: true,
    shareId: 17590,
    account: "40701810000000001302",
    merchantId: "MA0000659950",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов «Мои акции»",
    shortName: "ОПИФ РФИ «Мои акции»",
    qrName: "ОПИФ Мои акции",
    available: true,
    shareId: 541872,
    account: "40701810500000001297",
    merchantId: "MA0000659962",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов «Мои облигации»",
    shortName: "ОПИФ РФИ «Мои облигации»",
    qrName: "ОПИФ Мои облигации",
    available: true,
    shareId: 17586,
    account: "40701810400000001300",
    merchantId: "MA0000659958",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов «Моя пенсия»",
    shortName: "ОПИФ РФИ «Моя пенсия»",
    qrName: "ОПИФ Моя пенсия",
    available: true,
    shareId: 17587,
    account: "40701810100000001299",
    merchantId: "MA0000659956",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов «Мой сейф»",
    shortName: "ОПИФ РФИ «Мой сейф»",
    qrName: "ОПИФ Мой сейф",
    available: false,
    shareId: 639383,
    account: "40701810700000001301",
    merchantId: "MA0000659945",
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов Ингосстрах - Международные рынки",
    shortName: 'ОПИФ РФИ "Ингосстрах-Международные рынки"',
    available: false,
    shareId: 10277947,
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов Ингосстрах - драгоценные металлы",
    shortName: 'ОПИФ РФИ "Ингосстрах-драгоценные металлы"',
    available: false,
    shareId: 13035568,
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов Ингосстрах - передовые технологии",
    shortName: 'ОПИФ РФИ "Ингосстрах-передовые технологии"',
    available: false,
    shareId: 13035217,
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
  {
    name: "ОПИФ рыночных финансовых инструментов Ингосстрах-валютные облигации",
    shortName: 'ОПИФ РФИ "Ингосстрах-валютные облигации"',
    available: false,
    shareId: 7596108,
    currency: "₽",
    currencyText: "Российский рубль",
    currencySlug: "RUB",
    minLimit: 1000,
    maxLimit: 15000000,
  },
];

export default pifFunds.filter((f) => f.available);
