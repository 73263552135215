import { FC, SetStateAction } from "react";

import {
  Button,
  ButtonProps,
  ModalSMSBlock,
  ModalSMSBlockProps,
  useToggle,
} from "ii-ui-kit";

import { SuccessIcon } from "core/components/svg";
import { ROUNDS_TIMEOUT_MAP } from "core/const/rounds-timeout-map";

type Props = Pick<ModalSMSBlockProps, "validate" | "sentTo"> &
  Pick<ButtonProps, "color" | "disabled" | "isLoading"> & {
    isSuccessSign: boolean;
    onSuccessSign?: (value: SetStateAction<boolean>) => void;
    onErrorSign?: () => void;
    title: string;
    className?: string;
    onSignClick: () => void | Promise<void>;
  };

export const SignBySms: FC<Props> = ({
  color = "secondary",
  isSuccessSign,
  onSuccessSign,
  onSignClick,
  onErrorSign,
  validate: onValidate,
  sentTo,
  title,
  disabled,
  className,
  isLoading,
}) => {
  const [isSignModalShown, toggleIsSignModalShown] = useToggle(false);

  const handleValidateClient = async (code: string) => {
    const validated = await onValidate(code);

    if (validated) {
      toggleIsSignModalShown();
      onSuccessSign && onSuccessSign(true);
    }

    return validated;
  };

  const handleClientSignClick = async () => {
    try {
      await onSignClick();

      toggleIsSignModalShown();
    } catch {
      onErrorSign && onErrorSign();
    }
  };

  return (
    <>
      <ModalSMSBlock
        shown={isSignModalShown}
        onClose={toggleIsSignModalShown}
        validate={handleValidateClient}
        codeLength={6}
        sentTo={sentTo}
        onResend={onSignClick}
        defaultTimeout="300s"
        roundsTimeoutMap={ROUNDS_TIMEOUT_MAP}
      />
      <Button
        disabled={disabled || isSuccessSign}
        className={className}
        renderLeftContent={isSuccessSign ? () => <SuccessIcon /> : undefined}
        onClick={handleClientSignClick}
        color={color}
        size="l"
        isLoading={isLoading}
      >
        {title}
      </Button>
    </>
  );
};
