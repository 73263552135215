import { EditIcon, TickInCircleFilledIcon, TickInCircleIcon } from "../svg";

const renderIcon = (type: "active" | "passed" | "default") => {
  let icon;
  switch (type) {
    case "active":
      icon = <EditIcon />;
      break;
    case "passed":
      icon = <TickInCircleFilledIcon />;
      break;
    case "default":
    default:
      icon = <TickInCircleIcon />;
  }
  return icon;
};

export const utils = {
  renderIcon,
};
