import * as yup from "yup";

import { PHONE_REGEX } from "core/utils/regex-rules";

import { IPhoneFormFields } from "../components/phone-form/types";

/** Схема валидации формы ввода телефона */
export const authFormSchema = yup
  .object()
  .shape<Record<keyof IPhoneFormFields, yup.AnySchema>>({
    phone: yup
      .string()
      .required("Укажите номер телефона")
      .matches(PHONE_REGEX, "Укажите номер телефона"),
  });
