import { FC } from "react";

import RequisiteWithCopy from "core/components/requisite-with-copy";
import { BankModel, ReceiverInfoModel } from "pages/payment-page/types";

import PaymentButtonSection from "../payment-button-section";
import QrCode from "../qr-code";

import PaymentPurposeSection from "./payment-purpose-section";

import "./requisites.scss";

type RequisitesProps = {
  bank: BankModel;
  receiverInfo: ReceiverInfoModel;
  paymentPurpose: string;
  amountMoney: number;
  payerInfo: {
    lastName: string;
    firstName: string;
    patronymic: string;
  };
};

const Requisites: FC<RequisitesProps> = ({
  bank,
  receiverInfo,
  paymentPurpose,
  payerInfo,
  amountMoney,
}) => {
  return (
    <>
      <div className="requisites__wrapper">
        <div className="requisites__section">
          <div className="requisites__section-title">Получатель:</div>
          <span className="requisites__value">{receiverInfo.name}</span>
          <RequisiteWithCopy value={receiverInfo.inn} name="ИНН" />
          <RequisiteWithCopy value={receiverInfo.kpp} name="КПП" />
        </div>
        <div className="requisites__section">
          <div className="requisites__section-title">Банк получателя:</div>
          <span className="requisites__value">{bank.label}</span>
          <RequisiteWithCopy value={bank.requisites.bik} name="БИК" />
          <RequisiteWithCopy value={bank.requisites.corrAccount} name="к/с" />
          <RequisiteWithCopy
            value={bank.requisites.checkingAccount}
            name="р/с"
          />
        </div>
        <PaymentPurposeSection value={paymentPurpose} />
        <QrCode
          bank={bank}
          receiverInfo={receiverInfo}
          paymentPurpose={paymentPurpose}
          payerInfo={payerInfo}
          amountMoney={amountMoney}
        />
      </div>

      <PaymentButtonSection />
    </>
  );
};

export default Requisites;
