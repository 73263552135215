import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASE_API_URL } from "const";
import { ICommonResponse } from "services/common-types";

import {
  IAgentSendSmsRequest,
  IAgentsSendSignCodeRequest,
  IAgentsVerifySignCodeRequest,
  IAgentsVerifySignCodeResponse,
} from "./types";

export const agentsApi = createApi({
  reducerPath: "agentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_API_URL}api/Agent`,
  }),
  endpoints: (builder) => ({
    sendSignCode: builder.mutation<
      ICommonResponse<string>,
      IAgentsSendSignCodeRequest
    >({
      query: (body) => ({
        url: "/SendSignCode",
        method: "POST",
        body,
      }),
    }),
    verifySignCode: builder.mutation<
      ICommonResponse<IAgentsVerifySignCodeResponse>,
      IAgentsVerifySignCodeRequest
    >({
      query: (body) => ({
        url: "/VerifySignCode",
        method: "POST",
        body,
      }),
    }),
    sendSms: builder.mutation<void, IAgentSendSmsRequest>({
      query: (body) => ({
        url: "/SendSbpSms",
        method: "POST",
        body,
      }),
    }),
  }),
});
