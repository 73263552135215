import { ProductType } from "core/types/entities/product-type";
import { formatNumberToRubCurrency } from "core/utils/number-formatters";
import { ClientCheckStatus } from "pages/fund-select/types";
import { ILandingAgentsClientCheckResultResponse } from "services/agents-landing-service/types";

import { pageContent } from "./data/page-content";

export const getClientCheckStatus = (
  data: ILandingAgentsClientCheckResultResponse
) => {
  if (data.isProhibited) {
    return ClientCheckStatus.Failed;
  }

  switch (data.prismaStatus) {
    case 1:
      return ClientCheckStatus.Success;
    case 2:
      return ClientCheckStatus.Failed;
    default:
      return ClientCheckStatus.Pending;
  }
};

/** Получить текст для ограничений платежа */
export const getAmountBoundariesText = ({
  min,
  max,
}: {
  min: number;
  max: number;
}) => {
  if (!min && !max) {
    return undefined;
  }

  const minText = min ? `от ${formatNumberToRubCurrency(min)}` : "";
  const maxText = max ? `до ${formatNumberToRubCurrency(max)}` : "";

  return `Введите сумму ${minText} ${maxText}`;
};

/** Получить контент страницы */
export const getFundSelectPageContent = (productType: ProductType) => {
  return pageContent[productType];
};
