import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Input, InputWithSwitch, useClickOutside } from "ii-ui-kit";
import { RefCallBack } from "react-hook-form";

import AutocompleteOption from "core/components/autocomplete/autocomplete-option";

import "./index.scss";

type InputProps = {
  inputProps: ComponentProps<typeof Input>;
};
type InputWithSwitchProps = ComponentProps<typeof InputWithSwitch> & {
  inputProps: ComponentProps<typeof InputWithSwitch>["inputProps"] & {
    ref: RefCallBack;
  };
};

const isInputWithSwitchProps = (
  inputProps: InputProps | InputWithSwitchProps
): inputProps is InputWithSwitchProps => {
  return (inputProps as InputWithSwitchProps).switchProps !== undefined;
};

type Props<T> = {
  options: T[];
  optionRender?: (option: T) => React.ReactNode;
  onOptionClick?: (option: T) => void;
  componentProps: InputProps | InputWithSwitchProps;
};

const Autocomplete = <T,>({
  options,
  optionRender,
  onOptionClick,
  componentProps,
}: Props<T>) => {
  const {
    inputProps: { ref, ...inputProps },
  } = componentProps;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef && typeof ref === "function") {
      ref(inputRef.current);
    }
  }, [inputRef, ref]);

  const suggestionsRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const handleInputFocus = useCallback(() => setShowPopup(true), []);
  const handleInputBlur = useCallback(() => setShowPopup(false), []);
  useClickOutside(suggestionsRef, handleInputBlur);

  const handleSuggestionClick = useCallback(
    (option: T) => {
      onOptionClick && onOptionClick(option);
      handleInputBlur();
    },
    [onOptionClick, handleInputBlur]
  );

  return (
    <div className="autocomplete">
      {isInputWithSwitchProps(componentProps) ? (
        <InputWithSwitch
          {...componentProps}
          ref={inputRef}
          inputProps={{
            ...inputProps,
            onFocus: handleInputFocus,
            addContainerClass: "autocomplete__input",
          }}
        />
      ) : (
        <Input
          {...inputProps}
          ref={inputRef}
          onFocus={handleInputFocus}
          addContainerClass="autocomplete__input"
        />
      )}
      {showPopup && options.length > 0 && (
        <div ref={suggestionsRef} className="autocomplete__popup">
          {options.map((option, idx) => (
            <AutocompleteOption
              key={idx}
              option={option}
              optionRender={optionRender}
              onOptionClick={handleSuggestionClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
