import classNames from "classnames";

import { useAppSelector } from "core/store/hooks";
import { appSelectors } from "core/store/reducers/app-reducer";

import { Logo } from "../logo/logo";
import { Steps } from "../steps/steps";

import { modifyStepsDependOnCurrent } from "./utils";

import "./header.scss";

type Props = {
  addClass?: string;
  showSteps?: boolean;
};

export const Header: React.FC<Props> = ({ addClass, showSteps = true }) => {
  const currentStep = useAppSelector(appSelectors.selectCurrentStep);
  const steps = modifyStepsDependOnCurrent(currentStep);

  return (
    <header className={classNames("header", addClass)}>
      <Logo />
      {showSteps && <Steps addClass="header__steps" items={steps} />}
      {/* Empty block to justify steps at center */}
      <div />
    </header>
  );
};
