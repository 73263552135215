import { createTransform } from "redux-persist";

import { Reducers } from "core/enums/reducers";

import { IAppState } from "./types";

/**
 *  Трансформация app state для persist'а
 */
export const appStateTransform = createTransform(
  undefined,
  (outboundState: IAppState) => ({
    ...outboundState,
    snackbar: { ...outboundState.snackbar, isOpen: false },
  }),
  {
    whitelist: [Reducers.App],
  }
);
