import { FieldValues, UseFormReturn, FieldPath } from "react-hook-form";

export const getMeta = <T extends FieldValues = FieldValues>(
  form: UseFormReturn<T>,
  field: FieldPath<T>,
  customErrorMessage?: string
) => ({
  errorText: customErrorMessage || form.getFieldState(field).error?.message,
  validationStatus:
    customErrorMessage || form.getFieldState(field).error
      ? ("error" as const)
      : undefined,
  form: "formField",
});

export const getCustomMeta = (customErrorMessage?: string) => ({
  errorText: customErrorMessage,
  validationStatus: customErrorMessage ? ("error" as const) : undefined,
  form: "formField",
});
