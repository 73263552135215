import PageWrapper from "core/components/page-wrapper";
import { getCurrency, numberFormat } from "core/utils/number-formatters";

import PaymentWays from "./components/payment-ways";
import { usePaymentPageState } from "./state";

import "./index.scss";

const PaymentPage = () => {
  const { paymentInfo, name, fullName, mobile } = usePaymentPageState();

  return (
    <PageWrapper title="Оплата" className="payment">
      <div className="payment__fio">{fullName}</div>
      <div className="payment__order-info">
        <div className="payment__summary">
          <div className="payment__fund">{paymentInfo.fund}</div>
          <div className="payment__amount">
            {numberFormat({
              value: paymentInfo.amount,
              postfix: getCurrency("RUB"),
            })}
          </div>
          <div className="payment__order">
            Номер заявки: {paymentInfo.orderNumber}
          </div>
        </div>

        <PaymentWays
          payerInfo={{ ...name, phoneNumber: mobile }}
          amountMoney={paymentInfo.amount}
        />
      </div>
    </PageWrapper>
  );
};

export default PaymentPage;
