import { Modal } from "ii-ui-kit";

import { PhoneForm } from "../phone-form";
import "./index.scss";

export const PhoneModal = () => {
  return (
    <Modal className="phone-modal" shown swipeable closable={false}>
      <div className="phone-modal__title">Авторизация агента</div>
      <PhoneForm className="phone-modal__form" />
    </Modal>
  );
};
