/** Количество документов для физической подписи */
export const DOCUMENT_COUNT = 3;

/** Максимальный размер документов для физ подписи на мобильных устройствах */
export const DOCUMENTS_MOBILE_SIZE = 15 * 1024 * 1024;

/** Максимальный размер документов для физ подписи на десктоп */
export const DOCUMENTS_SIZE = 30 * 1024 * 1024;

/** Таймаут для отправки повторного сообщения клиенту */
export const SEND_CLIENT_TIMEOUT = "60000";
