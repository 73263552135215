import { Button, CountDown, DownloadPanel, Loader } from "ii-ui-kit";

import { BackdropLoader } from "core/components/backdrop-loader";
import PageWrapper from "core/components/page-wrapper";
import { ClientSignButton, SignBySms } from "core/components/sign-button";
import { formatPhoneNumber } from "core/utils/phone-utils";

import RealSign from "./components/real-sign";
import { useDocumentsPageState } from "./state";

import "./index.scss";

const DocumentsPage = () => {
  const {
    documentsGUID,
    documentsReadyResult,
    fund,
    isElectronicSign,
    setIsElectronicSign,
    handleAgentSign,
    agentInfo,
    handleAgentValidate,
    handleClientSign,
    isClientSignLoading,
    mobile,
    clientSignedInfo,
    agentSignedInfo,
    clientSendLinkCountdown,
    sendAgentSignMutationState,
    isDocumentSignedLoading,
  } = useDocumentsPageState();

  return (
    <PageWrapper title="Подписание документов">
      {!documentsGUID ? (
        <Loader />
      ) : (
        <div className="documents">
          {isClientSignLoading ? (
            <div className="documents__note">
              <div className="documents__note-text">
                Ссылка на подписание документов отправлена на телефонный
                номер&nbsp;
                {mobile}
              </div>
              <div>
                <CountDown
                  countdown={clientSendLinkCountdown}
                  onTimedOutClick={handleClientSign}
                  timedOutText="Отправить ссылку повторно"
                  renderRunningText={(timeout) =>
                    `Отправить ссылку повторно можно через ${timeout}.`
                  }
                />
              </div>
            </div>
          ) : (
            <div className="documents__doc-group">
              <DownloadPanel
                text={`Комплект документов для ${fund}`}
                href={`/api/LandingAgent/DocumentDownload?documentGUID=${documentsReadyResult.data}`}
                className="documents__doc"
              />
            </div>
          )}

          <div className="documents__buttons-wrapper">
            {!isElectronicSign ? (
              <Button onClick={() => setIsElectronicSign(true)} size="l">
                Электронная подпись
              </Button>
            ) : (
              <>
                <ClientSignButton
                  isSuccessSign={Boolean(clientSignedInfo.code)}
                  title="Подпись клиента"
                  onClickSign={handleClientSign}
                  isLoading={isClientSignLoading}
                />
                <SignBySms
                  disabled={!clientSignedInfo.code}
                  isSuccessSign={Boolean(agentSignedInfo.code)}
                  title="Подпись агента"
                  sentTo={formatPhoneNumber(
                    agentInfo?.mobile || "Номер агента"
                  )}
                  onSignClick={handleAgentSign}
                  validate={handleAgentValidate}
                  isLoading={sendAgentSignMutationState.isLoading}
                />
              </>
            )}
            {!isElectronicSign && <RealSign />}
          </div>
        </div>
      )}
      <BackdropLoader isLoading={isDocumentSignedLoading} />
    </PageWrapper>
  );
};
export default DocumentsPage;
