import { createSelector } from "@reduxjs/toolkit";

import { Reducers } from "core/enums/reducers";
import { RootState } from "core/store";
import { personalDataSelectors } from "core/store/reducers/personal-data-reducer";
import { generatePaymentPurpose } from "core/utils/payment-utils/generate-payment-purpose";

import { appSelectors } from "../app-reducer";

const selectPaymentInfo = (state: RootState) => state[Reducers.Payment];

const selectPaymentPurpose = createSelector(
  selectPaymentInfo,
  appSelectors.selectProductType,
  personalDataSelectors.selectFullName,
  (paymentInfo, productType, fullName) =>
    generatePaymentPurpose({ productType, fullName, paymentInfo })
);

export const paymentSelectors = {
  selectPaymentInfo,
  selectPaymentPurpose,
};
