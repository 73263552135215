import { STEPS } from "core/const/steps";
import STEP_KEYS from "core/enums/steps";

/**
 * Модифицировать массив шагов с учетом текущего шага
 * @param steps Шаги
 * @param currentStep Текущий шаг
 */
export const modifyStepsDependOnCurrent = (currentStep: STEP_KEYS) => {
  const copySteps = [...STEPS];

  copySteps.forEach((step) => {
    if (step.key > currentStep) {
      step.isActive = false;
      step.isPassed = false;
    } else if (step.key < currentStep) {
      step.isActive = false;
      step.isPassed = true;
    } else {
      step.isActive = true;
      step.isPassed = false;
    }
  });

  return copySteps;
};
