import React, { ComponentProps, FC } from "react";

import { ModalProps } from "ii-ui-kit";

import ErrorModal from "core/components/error-modal";

type Props = Pick<ModalProps, "shown"> & {
  onClose: ComponentProps<typeof ErrorModal>["onClose"];
};

const RequirementsErrorModal: FC<Props> = ({ shown, onClose }) => {
  return (
    <ErrorModal
      onClose={onClose}
      errorTitle="К сожалению, в данный момент мы не можем открыть счёт для данного
    клиента онлайн."
      errorText={
        <>
          <p>
            Для рассмотрения возможности оказания услуг и оформления документов
            необходимо обратиться в офис.
          </p>
          <p>
            С клиентом свяжутся сотрудники УК ИИ в ближайшее время (телефон
            клиента в УК ИИ уже есть, от клиента никаких действий не требуется).
            Приносим извинения за доставленные неудобства!
          </p>
        </>
      }
      shown={shown}
    />
  );
};

export default RequirementsErrorModal;
