import React from "react";

import { Button, Informer } from "ii-ui-kit";

import { INGOSINVEST_SITE } from "const";
import PageWrapper from "core/components/page-wrapper";
import ResultCard from "core/components/result-card";

import { useDocumentsSuccessPageState } from "./state";

import "./index.scss";

const DocumentsSuccess = () => {
  const { handleButtonClick, email } = useDocumentsSuccessPageState();

  return (
    <PageWrapper title="Оплата" className="documents-success">
      <div className="documents-success__content">
        <ResultCard
          type="document-success"
          title="Поздравляем!"
          text="Пакет документов успешно подписан обеими сторонами."
        />
        <div className="documents-success__summary">
          Мы отправили на почту клиента
          <br />
          <span className="documents-success__email">{email}</span>
          <br />
          подписанные документы
        </div>
        <Informer
          isOpen
          isDisplayIcon
          type="info"
          text={
            <>
              При первом входе на{" "}
              <a href={INGOSINVEST_SITE} target="_blank">
                ingosinvest-am.ru
              </a>{" "}
              клиент сможет задать пароль для личного кабинета
            </>
          }
        />
        <Button onClick={handleButtonClick} color="primary">
          Продолжить
        </Button>
      </div>
    </PageWrapper>
  );
};

export default DocumentsSuccess;
