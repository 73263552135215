import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";

import RobotoBold from "assets/fonts/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
import { INGOSINVEST_SITE } from "const";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular as string },
    { src: RobotoBold as string, fontWeight: 700 },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    lineHeight: "2px",
    fontFamily: "Roboto",
    fontSize: "10px",
    padding: "0.75cm 1cm 1cm 1.25cm",
  },
  header: {
    textAlign: "center",
    fontWeight: "bold",
  },
  sectionHeader: {
    textDecoration: "underline",
  },
  sign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  signPart: {
    margin: "0 auto",
  },
});

type EdoDocumentProps = {
  firstName: string;
  lastName: string;
  patronymic: string;
  birthDate: string;
  passport: string;
  address: string;
  email: string;
  phone: string;
};

// Create Document Component
const EdoDocument = (client: Partial<EdoDocumentProps>) => {
  return (
    <Document language="rus">
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>ЗАЯВЛЕНИЕ О ПРИСОЕДИНЕНИИ</Text>
          <Text>
            к соглашению об электронном документообороте и предоставлении
          </Text>
          <Text>
            доступа к сервисам удаленного обслуживания АО УК
            «Ингосстрах-Инвестиции»
          </Text>
        </View>
        <View>
          <Text style={styles.sectionHeader}>
            1. Данные физического лица (клиента):
          </Text>
          <Text>1.1. Фамилия: {client.lastName}</Text>
          <Text>1.2. Имя: {client.firstName}</Text>
          <Text>1.3. Отчество: {client.patronymic}</Text>
          <Text>1.4. Дата рождения: {client.birthDate}</Text>
          <Text>
            1.5. Серия и номер паспорта (или номер иного документа,
            удостоверяющего личность): {client.passport}
          </Text>
          <Text>1.6. Место жительства: {client.address}</Text>
          <Text>1.7. Электронная почта: {client.email}</Text>
          <Text>
            1.8. Абонентский номер телефона подвижной радиотелефонной связи:
            {client.phone}
          </Text>
        </View>
        <View>
          <Text>
            Достоверность вышеуказанных персональных данных подтверждаю, а также
            подтверждаю, что указанный абонентский номер выделен мне на
            основании договора об оказании услуг подвижной радиотелефонной связи
            и указанный адрес электронной почты зарегистрирован на моё имя:
          </Text>
          <Text>Клиент:</Text>
          <View style={styles.sign}>
            <View>
              <Text>_______________________________/</Text>
              <Text style={styles.signPart}>подпись</Text>
            </View>
            <View>
              <Text>_________________________________________</Text>
              <Text style={styles.signPart}>фамилия И.О.</Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.sectionHeader}>2. Заявления и гарантии:</Text>
          <Text>
            2.1. Подписывая настоящее заявление, я выражаю намерение
            присоединиться к соглашению об электронном документообороте и
            предоставлении доступа к сервисам удаленного обслуживания
            Акционерного общества Управляющей компании «Ингосстрах-Инвестиции»
            (ОГРН 1027700339666; АО УК «Ингосстрах-Инвестиции»), далее
            именуемого «Соглашение», текст которого опубликован на официальном
            сайте АО УК «Ингосстрах-Инвестиции» в сети Интернет{" "}
            <Link src={INGOSINVEST_SITE}>
              <Text>{INGOSINVEST_SITE}</Text>
            </Link>
            .
          </Text>
          <Text>
            2.2. Подписывая настоящее заявление, я подтверждаю ознакомление с
            условиями Соглашения в полном объёме и подтверждаю своё
            волеизъявление на его заключение.
          </Text>
          <Text>
            2.3. Подписывая настоящее заявление, я обязуюсь обеспечить
            конфиденциальность ставших известными мне и(или) полученных мною на
            находящиеся у меня на законном основании технические устройства
            паролей, кодов, ссылок на ресурсы в сети Интернет (в том числе,
            ресурсы АО УК «Ингосстрах-Инвестиции»), иных данных, использование
            которых позволяет подписывать электронной подписью документы и(или)
            получать доступ к сервисам удаленного обслуживания АО УК
            «Ингосстрах-Инвестиции», то есть обязуюсь не передавать, не
            демонстрировать такие данные любым иным лицам и не пересылать их на
            любые иные технические устройства.
          </Text>
          <Text>
            2.4. Подписывая настоящее заявление, я обязуюсь использовать при
            подписании электронной подписью документов и (или) при получении
            доступа к сервисам удаленного обслуживания АО УК
            «Ингосстрах-Инвестиции» только находящиеся у меня на законном
            основании технические устройства, выделенный мне на основании
            договора об оказании услуг подвижной радиотелефонной связи
            абонентский номер телефона, и зарегистрированный на моё имя адрес
            электронной почты, и обязуюсь не предоставлять доступ к ним иным
            лицам при отсутствии уведомления АО УК «Ингосстрах-Инвестиции» о
            компрометации конфиденциальных, аутентификационных данных.
          </Text>
          <Text>
            Дата подписания настоящего заявления: «_____» _____________________
            20____ г.
          </Text>
          <Text>Клиент:</Text>
          <View style={styles.sign}>
            <View>
              <Text>_______________________________/</Text>
              <Text style={styles.signPart}>подпись</Text>
            </View>
            <View>
              <Text>_________________________________________</Text>
              <Text style={styles.signPart}>фамилия И.О.</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default EdoDocument;
