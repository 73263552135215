import { FC } from "react";

import RequisitesCopyIcon from "./requisites-copy-icon";
import "./index.scss";

type RequisiteWithCopyProps = {
  name: string;
  value: string;
};

const RequisiteWithCopy: FC<RequisiteWithCopyProps> = ({ name, value }) => {
  return (
    <div className="requisite-with-copy">
      <span className="requisite-with-copy__value">{`${name} ${value}`}</span>
      <RequisitesCopyIcon value={value} className="requisite-with-copy__icon" />
    </div>
  );
};

export default RequisiteWithCopy;
