import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { PRODUCTS_BASE_API_URL } from "const";
// import { fundsIds } from "pages/fund-select/data/funds";

import {
  IGetAllProductsResponse,
  IGetProductResponse,
  IProductPropsResponse,
} from "./types";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${PRODUCTS_BASE_API_URL}api/Products`,
  }),
  endpoints: (builder) => ({
    getProductById: builder.query<IGetProductResponse, number>({
      query: (productId) => ({
        url: `/${productId}`,
        method: "GET",
      }),
    }),
    getAllProducts: builder.query<IGetAllProductsResponse, void>({
      query: () => ({
        url: `/list`,
        method: "GET",
      }),
    }),

    getProductProps: builder.query<IProductPropsResponse, number>({
      query: (productId) => ({
        url: `/${productId}/tmProps`,
        method: "GET",
      }),
    }),
    // getAllProducts: builder.query<IProduct[], void>({
    //   queryFn: async (_, api) => {
    //     const products: IProduct[] = (
    //       await Promise.all(
    //         fundsIds.map((id) =>
    //           api
    //             .dispatch(productsApi.endpoints.getProductById.initiate(id))
    //             .unwrap()
    //         )
    //       )
    //     ).map((productResponse) => productResponse.body) as IProduct[];

    //     return { data: products };
    //   },
    // }),
  }),
});
