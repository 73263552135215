export type BankModel = {
  /** Имя (используется для дропдауна, как value) */
  name: string;
  /** Название для отображения */
  label: string;
  /** Реквизиты */
  requisites: {
    /** Расчетный счет */
    checkingAccount: string;
    /** Коррекционный счет */
    corrAccount: string;
    /** БИК */
    bik: string;
  };
};

export type ReceiverInfoModel = {
  /** ИНН */
  inn: string;
  /** КПП */
  kpp: string;
  /** Наименование получателя */
  name: string;
};

/** Статус регистрации qr кода */
export enum QRC_REGISTER_STATUS {
  /** Не запущен */
  NOT_STARTED = 0,
  /** Успешен */
  SUCCESS = 1,
  /** В процессе */
  PENDING = 2,
  /** Ошибка */
  ERROR = 3,
}

/** Бик банка */
export enum BANK_BIK_VALUE {
  /** Банк союз */
  SOYUZ = "044525148",
  /** ГПБ */
  GPB = "044525823",
}

/** Модель информации о платящем */
export type IPayerInfoModel = {
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество */
  patronymic: string;
  /** Телефон */
  phoneNumber: string;
};
