import { Navigate, RouteObject } from "react-router-dom";

import MainLayout from "core/components/main-layout";
import { AppRoutes } from "core/enums/routes";
import AuthPage from "pages/auth-page";
import { ClientDocumentsContainer } from "pages/client-documents";
import ClientDocumentsSuccess from "pages/client-documents-success";
import DocumentsPage from "pages/documents";
import DocumentsSuccess from "pages/documents-success";
import FundSelect from "pages/fund-select";
import PaymentError from "pages/payment-error";
import PaymentPage from "pages/payment-page";
import PaymentSuccess from "pages/payment-success";
import PersonalData from "pages/personal-data";
import ProductSelect from "pages/product-select";
import SmsPage from "pages/sms-page";

import { ProtectedRoute } from "./protected-route";

const routes: RouteObject[] = [
  {
    path: AppRoutes.Main,
    element: <Navigate to={AppRoutes.PersonalData} />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <MainLayout />,
        children: [
          { path: AppRoutes.ProductSelect, element: <ProductSelect /> },
          { path: AppRoutes.PersonalData, element: <PersonalData /> },
          { path: AppRoutes.FundSelect, element: <FundSelect /> },
          { path: AppRoutes.Documents, element: <DocumentsPage /> },
          { path: AppRoutes.DocumentsSuccess, element: <DocumentsSuccess /> },
          { path: AppRoutes.Payment, element: <PaymentPage /> },
          { path: AppRoutes.PaymentSuccess, element: <PaymentSuccess /> },
          { path: AppRoutes.PaymentError, element: <PaymentError /> },
        ],
      },
    ],
  },
  {
    path: AppRoutes.Auth,
    element: <AuthPage />,
  },
  { path: AppRoutes.Sms, element: <SmsPage /> },
  {
    element: <MainLayout showSteps={false} />,
    children: [
      {
        path: AppRoutes.ClientDocumentsSign,
        element: <ClientDocumentsContainer />,
      },
      {
        path: AppRoutes.ClientDocumentsSignSuccess,
        element: <ClientDocumentsSuccess />,
      },
    ],
  },
];

export default routes;
