import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { SUGGESTIONS_API_TOKEN } from "const";
import {
  IAddressSuggestion,
  IBankSuggestion,
  IFmsCodeSuggestion,
  ISuggestion,
  ISuggestionRequest,
  ISuggestionsResponse,
} from "services/suggestions-service/types";

export const suggestionsApi = createApi({
  reducerPath: "suggestionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest",
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Token ${SUGGESTIONS_API_TOKEN}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    suggestName: builder.query<
      ISuggestionsResponse<ISuggestion>,
      ISuggestionRequest
    >({
      query: (body) => ({
        url: "/fio",
        method: "POST",
        body,
      }),
    }),
    suggestAddress: builder.query<
      ISuggestionsResponse<IAddressSuggestion>,
      ISuggestionRequest
    >({
      query: (body) => ({
        url: "/address",
        method: "POST",
        body,
      }),
    }),
    suggestBank: builder.query<
      ISuggestionsResponse<IBankSuggestion>,
      ISuggestionRequest
    >({
      query: (body) => ({
        url: "/bank",
        method: "POST",
        body,
      }),
    }),
    suggestFmsCode: builder.query<
      ISuggestionsResponse<IFmsCodeSuggestion>,
      ISuggestionRequest
    >({
      query: (body) => ({
        url: "/fms_unit",
        method: "POST",
        body,
      }),
    }),
  }),
});
