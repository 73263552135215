import { useCallback } from "react";

import { Button, useToggle } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { ContinueModal } from "core/components/modals/continue-modal";
import { AppRoutes } from "core/enums/routes";
import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";

import "./index.scss";

export type PaymentButtonSectionProps = {
  hasInformer?: boolean;
};

const PaymentButtonSection = ({
  hasInformer = true,
}: PaymentButtonSectionProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isVisibleExitModal, toggleExitModal] = useToggle(false);
  const [isVisibleRegisterModal, toggleRegisterModal] = useToggle(false);

  const handleRegisterNew = useCallback(() => {
    dispatch(appActions.clearStore());
    navigate(AppRoutes.ProductSelect);
  }, [dispatch, navigate]);

  const handleExit = useCallback(() => {
    dispatch(appActions.clearStore());
  }, [dispatch]);

  return (
    <>
      <div className="payment-button-section">
        {hasInformer && (
          <div className="payment-button-section__note">
            После перевода средств нажмите «Выйти» или «Зарегистрировать нового
            клиента»
          </div>
        )}

        <div className="payment-button-section__buttons">
          <Button
            onClick={toggleExitModal}
            className="payment-button-section__exit"
            color="secondary"
          >
            Выйти
          </Button>
          <Button
            onClick={toggleRegisterModal}
            className="payment-button-section__register-new"
          >
            Зарегистрировать нового клиента
          </Button>
        </div>
      </div>
      <ContinueModal
        isShown={isVisibleExitModal}
        title="Хотите выйти?"
        text="Вы собираетесь выйти из онбординга текущего клиента на первый этап «Авторизация агента»"
        onClose={toggleExitModal}
        onContinue={handleExit}
      />

      <ContinueModal
        isShown={isVisibleRegisterModal}
        title="Хотите выйти?"
        text="Вы собираетесь выйти из онбординга текущего клиента на второй этап “Выбор типа операции”"
        onClose={toggleRegisterModal}
        onContinue={handleRegisterNew}
      />
    </>
  );
};

export default PaymentButtonSection;
