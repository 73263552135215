import { FC } from "react";

import classNames from "classnames";
import { Svg, useToggle } from "ii-ui-kit";

import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";

import { ExitModal } from "../modals/exit-modal/exit-modal";

import "./index.scss";

export type PageWrapperProps = {
  isDisplayClose?: boolean;
  title?: string;
  className?: string;
};

const PageWrapper: FC<PageWrapperProps> = ({
  isDisplayClose = true,
  title,
  children,
  className,
}) => {
  const [isVisibleExitModal, toggleExitModal] = useToggle(false);
  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(appActions.clearStore());
  };

  return (
    <div className={classNames("page-wrapper", className)}>
      <ExitModal
        shown={isVisibleExitModal}
        onClose={toggleExitModal}
        onExit={handleExit}
      />

      <div className="page-wrapper__head">
        {isDisplayClose && (
          <div onClick={toggleExitModal} className="page-wrapper__exit">
            <Svg name="cross" />
          </div>
        )}
      </div>

      <div className="page-wrapper__content">
        {title && <div className="page-wrapper__title">{title}</div>}
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
