import { useExhaustiveEffect } from "ii-ui-kit";

import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";

export const useAuthPageState = () => {
  const dispatch = useAppDispatch();

  useExhaustiveEffect(() => {
    dispatch(appActions.clearStore());
  }, []);
};
