import { useExhaustiveEffect } from "ii-ui-kit";

/**
 * Хук подписки на событие
 * @param event Событие
 * @param handler Обработчик
 * @param deps Зависимости для эффекта
 */
export function useSubscribeEvent(
  event: string,
  handler: EventListenerOrEventListenerObject,
  deps?: unknown[]
) {
  useExhaustiveEffect(() => {
    document.addEventListener(event, handler);

    return () => {
      document.removeEventListener(event, handler);
    };
  }, deps || []);
}
