import { FC } from "react";

import { Button, Modal } from "ii-ui-kit";

import "./index.scss";

type ClientDocumentsErrorModalProps = {
  isOpen: boolean;
  description?: string;
  onClose: () => void;
};

const ClientDocumentsErrorModal: FC<ClientDocumentsErrorModalProps> = ({
  isOpen,
  description,
  onClose,
}) => {
  return (
    <Modal
      swipeable
      closable={false}
      className="client-documents-error-modal"
      shown={isOpen}
    >
      <div className="client-documents-error-modal__title">
        Что-то пошло не так
      </div>
      <div className="client-documents-error-modal__text">
        {description && <p>{description}</p>}
        <p> Подписание недоступно, обратитесь к своему агенту</p>
      </div>
      <div className="client-documents-error-modal__button-wrapper">
        <Button
          className="client-documents-error-modal__button"
          onClick={onClose}
          size="l"
        >
          Выйти
        </Button>
      </div>
    </Modal>
  );
};

export default ClientDocumentsErrorModal;
