import { Button } from "ii-ui-kit";

import PageWrapper from "core/components/page-wrapper";

import AddressSections from "./components/address-sections";
import EdoSection from "./components/edo-section";
import InnSection from "./components/inn-section";
import LoadPassportSection from "./components/load-passport-section";
import PassportDataSection from "./components/passport-data-section";
import RequirementsErrorModal from "./components/requirements-error-modal";
import RequirementsSection from "./components/requirements-section";
import { usePersonalDataPageState } from "./state";

import "./index.scss";

const PersonalData = () => {
  const {
    showModal,
    handleToggleModal,
    handleSubmit,
    handleCustomSubmit,
    watch,
    form,
    setValue,
    register,
    createLeadMutationState,
  } = usePersonalDataPageState();

  return (
    <PageWrapper title="Персональные данные">
      <RequirementsErrorModal onClose={handleToggleModal} shown={showModal} />

      <form
        onSubmit={handleSubmit(handleCustomSubmit)}
        className="personal-data"
      >
        <RequirementsSection
          watch={watch}
          form={form}
          setValue={setValue}
          register={register}
        />
        <PassportDataSection
          register={register}
          form={form}
          watch={watch}
          setValue={setValue}
        />
        <AddressSections
          register={register}
          form={form}
          watch={watch}
          setValue={setValue}
        />
        <InnSection setValue={setValue} register={register} form={form} />
        <LoadPassportSection form={form} setValue={setValue} />
        <EdoSection form={form} watch={watch} setValue={setValue} />

        <div className="personal-data__button-wrapper">
          <Button
            isLoading={createLeadMutationState.isLoading}
            className="personal-data__button"
            size="l"
            title="Продолжить"
            type="submit"
          />
          <p className="personal-data__disclaimer">
            Нажимая «Продолжить», я подтверждаю, что все данные были заполнены с
            согласия клиента
          </p>
        </div>
      </form>
    </PageWrapper>
  );
};

export default PersonalData;
