import { ProductType } from "core/types/entities/product-type";
import {
  IAddressSuggestion,
  ADDRESS_BLOCK_TYPE,
} from "services/suggestions-service/types";

/** Парсинг значения блок из предложенного дадатой адреса */
export const parseBlockFromSuggestion = (
  options: IAddressSuggestion
): {
  housing?: string;
  building?: string;
} => {
  const { block, block_type } = options.data;

  if (!block) {
    return { housing: undefined, building: undefined };
  }

  switch (block_type) {
    case ADDRESS_BLOCK_TYPE.BUILDING:
      return { housing: undefined, building: block };
    case ADDRESS_BLOCK_TYPE.HOUSING: {
      const splittedBlock = block
        .split(ADDRESS_BLOCK_TYPE.BUILDING)
        .map((element) => element.trim());

      if (splittedBlock.length > 1) {
        return { housing: splittedBlock[0], building: splittedBlock[1] };
      }

      return { housing: splittedBlock[0], building: undefined };
    }
  }
};

/** Получить категорию продукта */
export const getProductCategory = (productType: ProductType) => {
  switch (productType) {
    case ProductType.PIF:
      return "MF-Agent";
    case ProductType.DU:
      return "TM-Agent";
    case ProductType.IIS:
      return "IIS-Agent";
  }
};
