import logo from "assets/logo/logo.svg";

import "./index.scss";

const AuthLogo = () => {
  return (
    <div className="auth-logo">
      <img src={logo} />
    </div>
  );
};

export default AuthLogo;
