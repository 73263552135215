import React from "react";

import PageWrapper from "core/components/page-wrapper";
import ResultCard from "core/components/result-card";
import { useAppSelector } from "core/store/hooks";
import { appSelectors } from "core/store/reducers/app-reducer";
import PaymentButtonSection from "pages/payment-page/components/payment-button-section";
import { usePaymentPageInitialize } from "pages/payment-page/page-hooks";

import { getContentByProduct } from "./utils";

import "./index.scss";

const PaymentSuccess = () => {
  usePaymentPageInitialize();

  const productType = useAppSelector(appSelectors.selectProductType);

  const pageContent = getContentByProduct(productType);

  return (
    <PageWrapper title="Оплата" className="payment-success">
      <div className="payment-success__content">
        <ResultCard
          type={pageContent.type}
          title={pageContent.title}
          text={pageContent.paragraphs.map((text) => (
            <p key={text}>{text}</p>
          ))}
        />
        <PaymentButtonSection hasInformer={false} />
      </div>
    </PageWrapper>
  );
};

export default PaymentSuccess;
