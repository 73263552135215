import { FC, useCallback, useState } from "react";

import classNames from "classnames";

import { CUSTOM_EVENT } from "core/events/custom-event";
import { useSubscribeEvent } from "core/hooks/use-subscribe-event";

import { CopySuccessIcon, CopyIcon } from "../svg";

type RequisitesCopyIconProps = {
  /** Класс */
  className?: string;
  /** Значение */
  value: string;
};

const COPY_EVENT = new Event(CUSTOM_EVENT.REQUISITE_COPY);

/** Иконка копирования реквизитов с логикой изменения по клику */
const RequisitesCopyIcon: FC<RequisitesCopyIconProps> = ({
  className,
  value,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClickCopy = () => {
    void navigator.clipboard.writeText(value);
    document.dispatchEvent(COPY_EVENT);
    setIsCopied(true);
  };

  const handleEvent = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    setIsCopied(text === value);
  }, [value]);

  useSubscribeEvent(CUSTOM_EVENT.REQUISITE_COPY, () => void handleEvent(), [
    value,
  ]);

  const classes = classNames(className);

  return isCopied ? (
    <CopySuccessIcon className={classes} />
  ) : (
    <CopyIcon onClick={handleClickCopy} className={classes} />
  );
};

export default RequisitesCopyIcon;
