import React, { useCallback, useState } from "react";

import {
  Button,
  CountDown,
  Informer,
  useCountdown,
  useExhaustiveEffect,
} from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { INGOSINVEST_SITE } from "const";
import { AppRoutes } from "core/enums/routes";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { authSelectors } from "core/store/reducers/auth-reducer";
import { crmSelectors } from "core/store/reducers/crm-reducer";
import {
  paymentActions,
  paymentSelectors,
} from "core/store/reducers/payment-reducer";
import { personalDataSelectors } from "core/store/reducers/personal-data-reducer";
import { formatPhoneNumberWithoutSpacesAndBrackets } from "core/utils/phone-utils";
import { QRC_REGISTER_STATUS } from "pages/payment-page/types";
import { agentsApi } from "services/agents-service";
import { merchantApi } from "services/merchant-service";
import { MerchantQrcStatus } from "services/merchant-service/types";

import "./index.scss";

export type SbpPaymentProps = {
  phoneNumber: string;
};

const TIMEOUT = "60000";

const SbpPayment = ({ phoneNumber }: SbpPaymentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const countdown = useCountdown();
  const paymentInfo = useAppSelector(paymentSelectors.selectPaymentInfo);
  const { sbpPurpose } = useAppSelector(paymentSelectors.selectPaymentPurpose);

  const crmLeadId = useAppSelector(crmSelectors.selectCrmLeadId);
  const [qrcRegisterStatus, setQrcRegisterStatus] =
    useState<QRC_REGISTER_STATUS>(QRC_REGISTER_STATUS.NOT_STARTED);

  const [qrcDataMutation] = merchantApi.useQrcDataMutation();
  const [formDataMutation] = merchantApi.useFrontDataMutation();

  const registerQr = async () => {
    try {
      setQrcRegisterStatus(QRC_REGISTER_STATUS.PENDING);
      const {
        code,
        data: { qrcId, payload: qrLink },
      } = await qrcDataMutation({
        account: paymentInfo.account,
        amount: `${paymentInfo.amount * 100}`,
        callbackMerchantNotifications: `${INGOSINVEST_SITE}/payment/v1/callback`,
        currency: paymentInfo.currency,
        merchantId: paymentInfo.merchantId,
        paymentPurpose: sbpPurpose,
        qrcType: "02",
        templateVersion: "01",
      }).unwrap();

      if (code !== "000") {
        throw new Error();
      }

      dispatch(paymentActions.setPaymentInfo({ qrLink, qrcId }));

      await formDataMutation({
        qrcId,
        crmLeadId,
        crmApplicationNumber: paymentInfo.applicationNumber,
        selfPayment: true,
      }).unwrap();

      setQrcRegisterStatus(QRC_REGISTER_STATUS.SUCCESS);
    } catch {
      setQrcRegisterStatus(QRC_REGISTER_STATUS.ERROR);
    }
  };

  const agentEmployeeId = useAppSelector(authSelectors.selectAgentEmployeeID);
  const { mobile } = useAppSelector(personalDataSelectors.selectPersonalData);
  const [isSmsSent, setIsSmsSent] = useState(false);
  const [sendSmsMutation] = agentsApi.useSendSmsMutation();
  const [qrcStatusMutation] = merchantApi.useQrcStatusMutation();
  const [requestCallbackMutation] = merchantApi.useRequestCallbackMutation();

  const sendSms = useCallback(async () => {
    setIsSmsSent(true);

    try {
      await sendSmsMutation({
        agentEmployeeId,
        mobile: formatPhoneNumberWithoutSpacesAndBrackets(mobile),
        text: paymentInfo.qrLink,
      }).unwrap();

      countdown.start(TIMEOUT);
      const intervalId = setInterval(() => {
        void (async () => {
          try {
            const { status } = await qrcStatusMutation({
              qrcIds: [paymentInfo.qrcId],
            }).unwrap();

            switch (status) {
              case MerchantQrcStatus.ACCEPTED:
                clearInterval(intervalId);
                navigate(AppRoutes.PaymentSuccess);
                break;
              case MerchantQrcStatus.REJECTED:
                clearInterval(intervalId);
                navigate(AppRoutes.PaymentError);
                break;
            }
          } catch {
            clearInterval(intervalId);
            const { message, code } = await requestCallbackMutation({
              qrcId: paymentInfo.qrcId,
            }).unwrap();

            if (code === "000" && message === MerchantQrcStatus.ACCEPTED) {
              navigate(AppRoutes.PaymentSuccess);
            } else {
              navigate(AppRoutes.PaymentError);
            }
          }
        })();
      }, 5000);
    } catch {
      setTimeout(() => void sendSms(), 5000);
    }
  }, [
    agentEmployeeId,
    countdown,
    mobile,
    navigate,
    paymentInfo.qrLink,
    paymentInfo.qrcId,
    qrcStatusMutation,
    requestCallbackMutation,
    sendSmsMutation,
  ]);

  useExhaustiveEffect(() => {
    qrcRegisterStatus === QRC_REGISTER_STATUS.SUCCESS && void sendSms();
  }, [qrcRegisterStatus]);

  return (
    <div className="sbp-payment">
      {isSmsSent ? (
        <div className="sbp-payment__response">
          <p className="sbp-payment__title">
            Ссылка на оплату отправлена на телефонный номер
            <div>{phoneNumber}</div>
          </p>
          <p className="sbp-payment__subtitle">
            <CountDown
              countdown={countdown}
              onTimedOutClick={sendSms}
              timedOutText="Отправить повторно"
              renderRunningText={(timeout) =>
                `Отправить ссылку повторно можно через ${timeout}.`
              }
            />
          </p>
        </div>
      ) : (
        <div className="sbp-payment__request">
          <p className="sbp-payment__title">Отправьте ссылку для оплаты</p>
          <p className="sbp-payment__subtitle">на телефонный номер клиента</p>
          <Button
            size="l"
            isLoading={qrcRegisterStatus === QRC_REGISTER_STATUS.PENDING}
            className="sbp-payment__action"
            onClick={registerQr}
          >
            Отправить
          </Button>
          <Informer
            isOpen={qrcRegisterStatus === QRC_REGISTER_STATUS.ERROR}
            isDisplayIcon
            type="error"
            title="Сервер не отвечает"
            text="Повторите попытку или выберите другой способ оплаты"
            className="sbp-payment__error-informer"
          />
        </div>
      )}
    </div>
  );
};

export default SbpPayment;
