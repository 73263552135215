import { ProductType } from "core/types/entities/product-type";

import { ISuccessPaymentPageContent } from "./types";

const tmAndIiaSuccessPaymentContent: ISuccessPaymentPageContent = {
  title: "Ожидание платежа",
  type: "waiting",
  paragraphs: [
    "После обработки платежа денежные средства перечисляются на счёт доверительного управления.",
    "Срок исполнения — до 3 рабочих дней.",
  ],
};

const mfSuccessPaymentContent: ISuccessPaymentPageContent = {
  title: "Поздравляем!",
  type: "success",
  paragraphs: [
    "После обработки платежа инвестиционные паи будут зачислены на лицевой счёт клиента.",
    "Ориентировочный срок зачисления паев – до 5 рабочих дней.",
  ],
};

/** Контент для страницы */
export const pageSuccessPaymentContent = {
  [ProductType.PIF]: mfSuccessPaymentContent,
  [ProductType.DU]: tmAndIiaSuccessPaymentContent,
  [ProductType.IIS]: tmAndIiaSuccessPaymentContent,
};
