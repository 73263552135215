import { FC, useCallback } from "react";

import { Switch } from "ii-ui-kit";
import {
  Path,
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import Autocomplete from "core/components/autocomplete";
import useSuggestions from "core/hooks/use-suggestions";
import { getMeta } from "core/utils/form";
import { IPersonalDataForm } from "pages/personal-data/types";
import { parseBlockFromSuggestion } from "services/agents-landing-service/utils";
import { IAddressSuggestion } from "services/suggestions-service/types";

type AddressSectionsProps = {
  register: UseFormRegister<IPersonalDataForm>;
  form: UseFormReturn<IPersonalDataForm>;
  watch: UseFormWatch<IPersonalDataForm>;
  setValue: UseFormSetValue<IPersonalDataForm>;
};

const AddressSections: FC<AddressSectionsProps> = ({
  form,
  register,
  watch,
  setValue,
}) => {
  const [isRegistrationAddressSameAsActualAddress, registerAddress] = watch([
    "isRegisterAddressSameAsLiveAddress",
    "registerAddress.stringAddress",
  ]);

  const registerAddressSuggestions = useSuggestions({
    form,
    key: "registerAddress.stringAddress",
    suggestionsEndpoint: "suggestAddress",
  }) as IAddressSuggestion[];

  const liveAddressSuggestions = useSuggestions({
    form,
    key: "liveAddress.stringAddress",
    suggestionsEndpoint: "suggestAddress",
  }) as IAddressSuggestion[];

  const handleAddressOptionClick = useCallback(
    (key: Path<IPersonalDataForm>, option: IAddressSuggestion) => {
      const {
        value,
        data: {
          postal_code: zipCode,
          flat: apartment,
          region_kladr_id: regionCode,
          fias_id: fiasID,
          kladr_id: kladrID,
          country,
          region_with_type: region,
          city,
          street_with_type: street,
          house,
        },
      } = option;

      const { housing, building } = parseBlockFromSuggestion(option);

      setValue(key, {
        stringAddress: value,
        zipCode,
        apartment,
        regionCode,
        fiasID,
        kladrID,
        country,
        region,
        city,
        street,
        house,
        housing,
        building,
      });
    },
    [setValue]
  );

  return (
    <>
      <div className="personal-data__section">
        <div className="personal-data__subtitle">Адрес регистрации</div>
        <Autocomplete
          options={registerAddressSuggestions}
          onOptionClick={(option) =>
            handleAddressOptionClick("registerAddress", option)
          }
          optionRender={({ value }) => value}
          componentProps={{
            inputProps: {
              ...register("registerAddress.stringAddress"),
              ...getMeta(form, "registerAddress.stringAddress"),
              id: "registerAddress.stringAddress",
              label: "Город, улица, дом, корпус, строение, квартира",
              placeholder: "Город, улица, дом, корпус, строение, квартира",
              inputMode: "text",
            },
          }}
        />
        <Switch
          id="isRegisterAddressSameAsLiveAddress"
          label="Адрес регистрации совпадает с фактическим адресом проживания"
          labelPosition="left"
          value={isRegistrationAddressSameAsActualAddress}
          onChange={(value) => {
            if (value) {
              setValue("liveAddress.stringAddress", registerAddress);
            } else {
              setValue("liveAddress.stringAddress", "");
            }

            setValue("isRegisterAddressSameAsLiveAddress", value);
          }}
        />
      </div>

      {!isRegistrationAddressSameAsActualAddress && (
        <div className="personal-data__section">
          <div className="personal-data__subtitle">Фактический адрес</div>
          <Autocomplete
            options={liveAddressSuggestions}
            onOptionClick={(option) =>
              handleAddressOptionClick("liveAddress", option)
            }
            optionRender={({ value }) => value}
            componentProps={{
              inputProps: {
                ...register("liveAddress.stringAddress"),
                ...getMeta(form, "liveAddress.stringAddress"),
                id: "liveAddress",
                label: "Город, улица, дом, корпус, строение, квартира",
                placeholder: "Город, улица, дом, корпус, строение, квартира",
                inputMode: "text",
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default AddressSections;
