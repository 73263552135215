import { useState } from "react";

import { Dropdown, SingleProps } from "ii-ui-kit";

import ErrorMessage from "../error-message";

import SelectControl from "./select-control";
import "./index.scss";

type SelectProps<TValue> = {
  label: string;
  controlClassName?: string;
  error?: string;
} & Pick<SingleProps<TValue>, "value" | "onSelect" | "items" | "className">;

const Select = <TValue,>({
  value,
  label,
  controlClassName,
  error,
  ...rest
}: SelectProps<TValue>) => {
  const [isOpen, setIsOpen] = useState(false);

  const control = (
    <>
      <SelectControl
        className={controlClassName}
        isOpen={isOpen}
        value={value.label}
        label={label}
      />
      {error && <ErrorMessage errorText={error} />}
    </>
  );

  return (
    <Dropdown
      className="select"
      isOpen={isOpen}
      onChangeOpen={(value) => setIsOpen(value)}
      mode="single"
      value={value}
      control={control}
      {...rest}
    />
  );
};

export default Select;
