import React from "react";

import { Button } from "ii-ui-kit";

import { ContinueModal } from "core/components/modals/continue-modal";
import PageWrapper from "core/components/page-wrapper";
import ResultCard from "core/components/result-card";

import { usePaymentErrorPageState } from "./state";

import "./index.scss";

const PaymentError = () => {
  const { isVisibleExitModal, toggleExitModal, handleBackwards, handleExit } =
    usePaymentErrorPageState();

  return (
    <PageWrapper title="Оплата" className="payment-error">
      <ContinueModal
        isShown={isVisibleExitModal}
        title="Хотите выйти?"
        text="Вы собираетесь выйти из онбординга текущего клиента на первый этап «Авторизация агента»"
        onClose={toggleExitModal}
        onContinue={handleExit}
      />
      <ResultCard
        type="error"
        title="Оплата не прошла"
        text={
          <>
            <p>В процессе оплаты возникла ошибка.</p>
            <p>Вы можете вернуться назад и выбрать другой способ оплаты.</p>
          </>
        }
      />
      <div className="payment-error__button-section">
        <Button
          onClick={toggleExitModal}
          className="payment-error__button"
          color="secondary"
        >
          Выйти
        </Button>
        <Button
          onClick={handleBackwards}
          className="payment-error__button"
          color="primary"
        >
          Назад
        </Button>
      </div>
    </PageWrapper>
  );
};

export default PaymentError;
