import { FC } from "react";

import RequisitesCopyIcon from "core/components/requisite-with-copy/requisites-copy-icon";

type RequisiteSectionWithCopyProps = {
  value: string;
};

const PaymentPurposeSection: FC<RequisiteSectionWithCopyProps> = ({
  value,
}) => {
  return (
    <div className="requisites__section requisites__section--purpose">
      <div className="requisites__section-copy">
        <span className="requisites__section-title">Назначение платежа:</span>
        <RequisitesCopyIcon value={value} className="requisites__copy" />
      </div>
      {value}
    </div>
  );
};

export default PaymentPurposeSection;
