import { FC, useState } from "react";

import { Button, ButtonProps, ModalSMSBlock } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { Badge } from "core/components/badge";
import { ROUNDS_TIMEOUT_MAP } from "core/const/rounds-timeout-map";
import { AppRoutes } from "core/enums/routes";
import { useAppSelector } from "core/store/hooks";
import { authSelectors } from "core/store/reducers/auth-reducer";
import { formatPhoneNumber } from "core/utils/phone-utils";

import RealSignModal from "../real-sign-modal";

import "./index.scss";

type Props = Pick<ButtonProps, "disabled">;

const RealSign: FC<Props> = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowSign, setIsShowSign] = useState(false);
  const agentInfo = useAppSelector(authSelectors.selectAgentInfo);

  const navigate = useNavigate();

  const handleValidate = (code: string) => {
    // eslint-disable-next-line no-console
    console.log("VALIDATE_REAL_SIGN", code);

    if (code === "222222") {
      return false;
    }

    setIsShowSign(false);

    // TODO вылезает warning в консоли
    // (похоже, в связи с тем что перекидывает на страницу раньше, чем завершается validate смс)
    // Мб использовать таймаут с минимально подходящей задержкой? подумать)
    navigate(AppRoutes.Payment);

    return true;
  };

  const handleSign = () => {
    // eslint-disable-next-line no-console
    console.log("SIGN_REAL_DOCS");
  };

  const handleContinueClick = () => {
    setIsShowModal(false);
    // eslint-disable-next-line no-console
    console.log("CONTINUE_REAL_SEND");

    handleSign();
    setIsShowSign(true);
  };

  return (
    <>
      <RealSignModal
        isShow={isShowModal}
        onClose={() => setIsShowModal(false)}
        onContinue={handleContinueClick}
      />
      <ModalSMSBlock
        shown={isShowSign}
        onClose={() => setIsShowSign(false)}
        validate={handleValidate}
        codeLength={6}
        sentTo={formatPhoneNumber(agentInfo?.mobile || "")}
        onResend={handleSign}
        defaultTimeout="300s"
        roundsTimeoutMap={ROUNDS_TIMEOUT_MAP}
      />
      <Button
        disabled
        className="real-sign__button"
        onClick={() => setIsShowModal(true)}
        color="secondary"
        renderRightContent={() => <Badge content="Скоро!" />}
      >
        Физическая подпись
      </Button>
    </>
  );
};

export default RealSign;
