/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DATE_FORMAT } from "core/enums/date-format";
import { AppRoutes } from "core/enums/routes";
import useSuggestions from "core/hooks/use-suggestions";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { appSelectors } from "core/store/reducers/app-reducer";
import { authSelectors } from "core/store/reducers/auth-reducer";
import { crmActions, crmSelectors } from "core/store/reducers/crm-reducer";
import { fundSelectSelectors } from "core/store/reducers/fund-select-reducer/selectors";
import { IFundSelectForm } from "core/store/reducers/fund-select-reducer/types";
import { paymentActions } from "core/store/reducers/payment-reducer";
import { personalDataSelectors } from "core/store/reducers/personal-data-reducer";
import { IFund } from "core/types/entities/fund";
import { ProductType } from "core/types/entities/product-type";
import { formatDate } from "core/utils/date-formatters";
import {
  productTypeToFundsData,
  productTypeToFundsIdsMap,
} from "pages/fund-select/data/funds";
import { useFundSelectPageInitialize } from "pages/fund-select/page-hooks";
import { ClientCheckStatus } from "pages/fund-select/types";
import {
  getClientCheckStatus,
  getFundSelectPageContent,
} from "pages/fund-select/utils";
import { getFundSelectFormSchema } from "pages/fund-select/validation";
import { agentsLandingApi } from "services/agents-landing-service";
import { fundSelectAdapter } from "services/agents-landing-service/adapters";
import { ILandingAgentsCreateDocumentRequest } from "services/agents-landing-service/types";
import { productsApi } from "services/products-service";
import { IBankSuggestion } from "services/suggestions-service/types";

import { CURRENCYBONDS_ID } from "./const";

export const useFundSelectPageState = () => {
  useFundSelectPageInitialize();

  const productType = useAppSelector(appSelectors.selectProductType);
  const isSoyuzBank = useAppSelector(authSelectors.selectIsAgentSoyuzBank);

  const pageContent = getFundSelectPageContent(productType);

  // Funds
  const fundsIds = useMemo(() => {
    return isSoyuzBank && productType === ProductType.DU
      ? productTypeToFundsIdsMap[productType].filter(
          (id) => id === CURRENCYBONDS_ID
        )
      : productTypeToFundsIdsMap[productType];
  }, [isSoyuzBank, productType]);
  const localFundsData = useMemo(() => {
    return productTypeToFundsData[productType];
  }, [productType]);
  const { remoteFundsData } = productsApi.useGetAllProductsQuery(undefined, {
    selectFromResult: (result) => ({
      remoteFundsData:
        result.data?.body.filter((f) =>
          productTypeToFundsIdsMap[productType].includes(f.fansyId)
        ) || [],
    }),
  });
  const funds: IFund[] = useMemo(() => {
    return fundsIds.map((fundId) => {
      const localFund = localFundsData.find((i) => i.shareId === fundId)!;
      const remoteFund = remoteFundsData.find((i) => i.fansyId === fundId)!;
      return {
        ...localFund,
        shortName: remoteFund?.siteName ?? localFund?.shortName,
      };
    });
  }, [fundsIds, localFundsData, remoteFundsData]);

  const fundSelectFormSchema = useMemo(() => {
    return getFundSelectFormSchema(productType);
  }, [productType]);

  const defaultValues = useAppSelector(fundSelectSelectors.selectFundSelect);

  const form = useForm<IFundSelectForm>({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(fundSelectFormSchema),
  });
  const { handleSubmit, register, setValue, watch, formState } = form;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { errors } = formState;

  const fundsOptions = useMemo(() => {
    return funds.map(({ shortName, shareId }) => ({
      label: shortName,
      value: shareId,
    }));
  }, [funds]);

  const [selectedFund] = watch(["selectedFund"]);

  const selectedFundOption = selectedFund
    ? { label: selectedFund?.shortName, value: selectedFund?.shareId }
    : { label: "", value: 0 };

  const boundaries = {
    min: selectedFund?.minLimit || 0,
    max: selectedFund?.maxLimit || 0,
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const crmLeadId = useAppSelector(crmSelectors.selectCrmLeadId);
  const fullName = useAppSelector(personalDataSelectors.selectFullName);
  const [createDocumentsMutation, createDocumentsMutationState] =
    agentsLandingApi.useCreateDocumentsMutation();
  const handleCustomSubmit: SubmitHandler<IFundSelectForm> = useCallback(
    async (fields: IFundSelectForm) => {
      const createDocumentsRequestData: ILandingAgentsCreateDocumentRequest =
        fundSelectAdapter({
          crmLeadId,
          fullName,
          fundSelectData: fields,
        });
      const { crmDocPackId } = await createDocumentsMutation(
        createDocumentsRequestData
      ).unwrap();

      dispatch(crmActions.setDockPackId(crmDocPackId));

      const orderDate = formatDate(new Date(), DATE_FORMAT.FULL_DATE);

      selectedFund &&
        dispatch(
          paymentActions.setPaymentInfo({
            qrName: selectedFund.qrName,
            fund: selectedFund.name,
            fundId: selectedFund.shareId,
            amount: Number.parseInt(fields.estimate),
            account: selectedFund.account,
            merchantId: selectedFund.merchantId,
            currency: selectedFund.currencySlug,
            orderDate,
          })
        );

      navigate(AppRoutes.Documents);
    },
    [
      navigate,
      selectedFund,
      createDocumentsMutation,
      crmLeadId,
      dispatch,
      fullName,
    ]
  );

  const [clientCheckStatus, setClientCheckStatus] = useState(
    ClientCheckStatus.Pending
  );

  const clientCheckResult = agentsLandingApi.useGetClientCheckResultQuery(
    {
      CRMLeadId: crmLeadId,
    },
    {
      pollingInterval: 3000,
      skip: clientCheckStatus !== ClientCheckStatus.Pending,
    }
  );

  useEffect(() => {
    if (
      !clientCheckResult.isUninitialized &&
      clientCheckStatus !== ClientCheckStatus.Success
    ) {
      setClientCheckStatus(() => {
        if (!clientCheckResult.isSuccess) {
          return ClientCheckStatus.Pending;
        }

        return getClientCheckStatus(clientCheckResult.data);
      });
    }
  }, [
    clientCheckResult.data,
    clientCheckResult.isSuccess,
    clientCheckResult.isUninitialized,
    clientCheckStatus,
  ]);

  const bankSuggestions = useSuggestions({
    form,
    suggestionsEndpoint: "suggestBank",
    key: "bankAccount.bankName",
  }) as IBankSuggestion[];

  const [personalAccount] = watch(["paymentAccount"]);

  return {
    productType,
    personalAccount,
    clientCheckStatus,
    handleSubmit,
    handleCustomSubmit,
    createDocumentsMutationState,
    selectedFundOption,
    fundsOptions,
    formState,
    register,
    form,
    bankSuggestions,
    setValue,
    boundaries,
    funds,
    pageContent,
  };
};
