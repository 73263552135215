import { FC } from "react";

import { Svg } from "ii-ui-kit";

import "./index.scss";

type ErrorMessageProps = {
  errorText: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ errorText }) => {
  return (
    <div className="error-message">
      <span className="error-message__icon">
        <Svg name="cross-in-circle" />
      </span>
      <span className="error-message__text">{errorText}</span>
    </div>
  );
};

export default ErrorMessage;
