import { FormEventHandler, forwardRef, KeyboardEventHandler, Ref } from "react";

import { Input, InputProps } from "ii-ui-kit";

const AVAILABLE_KEYS = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ",",
  "Backspace",
  "Enter",
  "ArrowLeft",
  "ArrowRight",
  "Tab",
  "Delete",
];

const AVAILABLE_OPERATION_KEY_CODES = [
  "KeyC",
  "KeyV",
  "KeyA",
  "KeyX",
  "KeyZ",
  "Insert",
];

/** ХОК для навешивания обработки нажатия клавиш */
export const withCurrencyInputRulesHoc = (InputComponent: typeof Input) =>
  forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (
        (event.ctrlKey || event.metaKey || event.shiftKey) &&
        AVAILABLE_OPERATION_KEY_CODES.includes(event.code)
      ) {
        return;
      }

      if (event.key === "," && event.currentTarget.value.includes(",")) {
        event.preventDefault();
      }

      if (!AVAILABLE_KEYS.includes(event.key)) {
        event.preventDefault();
      }
    };

    const handleInput: FormEventHandler<HTMLInputElement> = (event) => {
      const checkedValue = /^\d*,?\d{0,2}/g.exec(event.currentTarget.value);
      // Предполагается, что checkedValue всегда true
      event.currentTarget.value = checkedValue ? checkedValue[0] : "";
    };

    return (
      <InputComponent
        ref={ref}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        inputMode="decimal"
        {...props}
      />
    );
  });
