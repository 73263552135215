import { useState, useEffect, useCallback } from "react";

import { useCountdown } from "ii-ui-kit";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "core/enums/routes";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { useSnackbar } from "core/store/reducers/app-reducer/hooks";
import { authSelectors } from "core/store/reducers/auth-reducer";
import { crmSelectors } from "core/store/reducers/crm-reducer";
import {
  paymentActions,
  paymentSelectors,
} from "core/store/reducers/payment-reducer";
import { personalDataSelectors } from "core/store/reducers/personal-data-reducer";
import { isFetchBaseQueryError } from "core/utils/api-error-utils";
import { useDocumentsPageInitialize } from "pages/documents/page-hooks";
import { agentClientSignApi } from "services/agent-client-sign";
import { agentsLandingApi } from "services/agents-landing-service";
import { ONBOARDING_TYPE } from "services/agents-landing-service/types";
import { agentsApi } from "services/agents-service";

import { SEND_CLIENT_TIMEOUT } from "./const";

export const useDocumentsPageState = () => {
  useDocumentsPageInitialize();

  const { fund, fundId } = useAppSelector(paymentSelectors.selectPaymentInfo);
  const { crmLeadId, dockPackId: crmDockPackId } = useAppSelector(
    crmSelectors.selectCrmInfo
  );

  const [documentsGUID, setDocumentsGUID] = useState<string>("");
  const documentsReadyResult = agentsLandingApi.useGetDocumentsReadyQuery(
    {
      crmDocPackId: crmDockPackId,
    },
    {
      pollingInterval: 3000,
      skip: Boolean(documentsGUID),
    }
  );

  useEffect(() => {
    documentsReadyResult.isSuccess &&
      setDocumentsGUID(documentsReadyResult.data);
  }, [documentsReadyResult]);

  const [isElectronicSign, setIsElectronicSign] = useState(false);

  // AGENT SIGN
  const [agentSignedInfo, setAgentSignedInfo] = useState({
    signedDate: "",
    code: "",
  });
  const agentEmployeeId = useAppSelector(authSelectors.selectAgentEmployeeID);
  const [sendAgentSignCodeMutation, sendAgentSignMutationState] =
    agentsApi.useSendSignCodeMutation();
  const [verifyAgentSignCodeMutation] = agentsApi.useVerifySignCodeMutation();
  const handleAgentSign = useCallback(async () => {
    await sendAgentSignCodeMutation({
      agentEmployeeId,
      crmLeadId,
      crmDockPackId,
      applicationType: "1",
    }).unwrap();
  }, [sendAgentSignCodeMutation, agentEmployeeId, crmLeadId, crmDockPackId]);
  const agentInfo = useAppSelector(authSelectors.selectAgentInfo);
  const handleAgentValidate = useCallback(
    async (code: string) => {
      try {
        const {
          body: { code: agentCode, verifyDateTime },
        } = await verifyAgentSignCodeMutation({
          agentEmployeeId,
          crmLeadId,
          crmDockPackId,
          code,
        }).unwrap();

        setAgentSignedInfo({
          code: agentCode,
          signedDate: verifyDateTime,
        });

        return true;
      } catch (e) {
        return false;
      }
    },
    [verifyAgentSignCodeMutation, agentEmployeeId, crmLeadId, crmDockPackId]
  );

  // CLIENT SIGN
  const clientSendLinkCountdown = useCountdown();
  const { mobile } = useAppSelector(personalDataSelectors.selectPersonalData);
  const [clientSignedInfo, setClientSignedInfo] = useState({
    signedDate: "",
    code: "",
  });
  const { actions: snackbarActions } = useSnackbar();

  const [isClientSignLoading, setIsClientSignLoading] = useState(false);

  const [createLink] = agentClientSignApi.useCreateLinkMutation();
  const [documentStatus] = agentClientSignApi.useLazyDocumentStatusQuery();

  const handleWaitClientSign = async (linkId: string) => {
    let interval: NodeJS.Timer | null = null;

    const handleClearInterval = () => {
      if (interval) {
        clearInterval(interval);
      }
    };

    try {
      await new Promise<void>((resolve, reject) => {
        const handleCheckSigningStatus = () => {
          documentStatus({ linkId })
            .unwrap()
            .then(({ body }) => {
              setClientSignedInfo({
                code: body.clientCode,
                signedDate: body.verifyTime,
              });

              handleClearInterval();
              resolve();
            })
            .catch((error) => {
              if (error && isFetchBaseQueryError(error)) {
                if (error.status !== 401) {
                  reject();
                }
              }
            });
        };

        interval = setInterval(handleCheckSigningStatus, 5000);
      });
    } catch {
      handleClearInterval();
    }
  };

  const handleClientSign = async () => {
    setIsClientSignLoading(true);
    clientSendLinkCountdown.start(SEND_CLIENT_TIMEOUT);

    try {
      const { body } = await createLink({
        phone: mobile.replace(/\D/g, ""),
        baseUrl: `${window.location.origin}${AppRoutes.ClientDocumentsSign}?linkId=`,
        documents: [{ id: documentsGUID, type: "0" }],
        fundId,
      }).unwrap();

      await handleWaitClientSign(body.linkId);
    } catch {
      snackbarActions.setSnackbarAlert({
        title: "Произошла непредвиденная ошибка",
        type: "danger",
        text: "Попробуйте совершить действие ещё раз или свяжитесь с нами",
        buttonText: "Связаться",
        onButtonClick: () => window.open("tel:88001004898", "_self"),
      });
    } finally {
      setIsClientSignLoading(false);
    }
  };

  // BOTH SIGNED
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getDocumentsSignedMutation, { isLoading: isDocumentSignedLoading }] =
    agentsLandingApi.useGetDocumentsSignedMutation();
  const handleSignedDocuments = useCallback(async () => {
    const {
      contracts: [contract],
    } = await getDocumentsSignedMutation({
      rejectReason: 0,
      crmDockPackId,
      smsCode: clientSignedInfo.code,
      orderSignDate: clientSignedInfo.signedDate,
      agentEmployeeOrderSignDate: agentSignedInfo.signedDate,
      agentEmployeeSmsCode: agentSignedInfo.code,
      onboardingType: ONBOARDING_TYPE.AGENT,
    }).unwrap();

    dispatch(
      paymentActions.setPaymentInfo({
        orderDate: contract.contractDate,
        orderNumber: contract.contractNumber,
        applicationNumber: contract.contractNumber,
      })
    );

    navigate(AppRoutes.DocumentsSuccess);
  }, [
    getDocumentsSignedMutation,
    dispatch,
    navigate,
    crmDockPackId,
    clientSignedInfo,
    agentSignedInfo,
  ]);

  useEffect(() => {
    agentSignedInfo.code && clientSignedInfo.code && handleSignedDocuments();
  }, [agentSignedInfo, clientSignedInfo, handleSignedDocuments]);

  return {
    documentsGUID,
    documentsReadyResult,
    fund,
    isElectronicSign,
    setIsElectronicSign,
    handleAgentSign,
    agentInfo,
    handleAgentValidate,
    handleClientSign,
    clientSignedInfo,
    agentSignedInfo,
    isClientSignLoading,
    mobile,
    clientSendLinkCountdown,
    sendAgentSignMutationState,
    isDocumentSignedLoading,
  };
};
