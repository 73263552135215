import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import GENDER from "core/enums/genders";
import { Reducers } from "core/enums/reducers";
import { appActions } from "core/store/reducers/app-reducer";
import { IPersonalDataForm } from "pages/personal-data/types";

const initialState: IPersonalDataForm = {
  isNotPublic: false,
  isNotForeignCitizen: false,
  isNotForeignResident: false,
  isNotBornFromUSA: false,
  isSelfInterest: false,
  isRefusesNotifications: undefined,
  lastName: "",
  firstName: "",
  hasNoPatronymic: false,
  patronymic: "",
  mobile: "",
  email: "",
  passportSeriesAndNumber: "",
  passportIssuedById: "",
  passportIssuedBy: "",
  passportIssueDate: "",
  birthPlace: "",
  birthDate: "",
  gender: GENDER.MALE,
  isRegisterAddressSameAsLiveAddress: true,
  registerAddress: {
    stringAddress: "",
  },
  liveAddress: {
    stringAddress: "",
  },
  inn: "",
  personDocumentScan: [
    { documentGUID: "" },
    { documentGUID: "" },
    { documentGUID: "" },
  ],
  acceptEDM: 0,
  hasAnotherIIS: false,
  anotherIISOrganization: "",
};

const personalDataSlice = createSlice({
  name: Reducers.PersonalData,
  initialState,
  reducers: {
    setPersonalData: (state, { payload }: PayloadAction<IPersonalDataForm>) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {
    [appActions.clearStore.type]: () => {
      return initialState;
    },
  },
});

export const personalDataActions = personalDataSlice.actions;

export const personalDataReducer = personalDataSlice.reducer;
