/** Привести строку к числу, заменив запятые точками */
export const userStringToNumber = (value: string) => {
  const result = Number(value.trim().replace(",", "."));

  return result;
};

/** Привести число к строке, заменив точку запятой */
export const numberToUserString = (value: number | string) => {
  const result = String(value).replace(".", ",");

  return result;
};
