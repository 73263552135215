import React, { FC } from "react";

import { Informer, Input, Switch } from "ii-ui-kit";
import {
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import CheckboxInput from "core/components/checkbox-input";
import { useAppSelector } from "core/store/hooks";
import { appSelectors } from "core/store/reducers/app-reducer";
import { ProductType } from "core/types/entities/product-type";
import { getMeta } from "core/utils/form";
import { IPersonalDataForm } from "pages/personal-data/types";

import "./index.scss";

type RequirementsSectionProps = {
  form: UseFormReturn<IPersonalDataForm>;
  register: UseFormRegister<IPersonalDataForm>;
  setValue: UseFormSetValue<IPersonalDataForm>;
  watch: UseFormWatch<IPersonalDataForm>;
};

const RequirementsSection: FC<RequirementsSectionProps> = ({
  form,
  register,
  setValue,
  watch,
}) => {
  const productType = useAppSelector(appSelectors.selectProductType);
  const [hasAnotherIIS] = watch(["hasAnotherIIS"]);
  return (
    <div>
      <CheckboxInput
        {...register("isNotPublic")}
        {...getMeta(form, "isNotPublic")}
        label="Клиент не является публичным российским или иностранным должностным лицом или его близким родственником"
        watch={watch}
        onChange={setValue}
      />
      <CheckboxInput
        {...register("isNotForeignCitizen")}
        {...getMeta(form, "isNotForeignCitizen")}
        label="Клиент не имеет гражданства и вида на жительство в иностранных государствах"
        watch={watch}
        onChange={setValue}
      />
      <CheckboxInput
        {...register("isNotForeignResident")}
        {...getMeta(form, "isNotForeignResident")}
        label="Клиент не является налоговым резидентом иностранного государства, включая США"
        watch={watch}
        onChange={setValue}
      />
      <CheckboxInput
        {...register("isNotBornFromUSA")}
        {...getMeta(form, "isNotBornFromUSA")}
        label="Клиент не рожден в США"
        watch={watch}
        onChange={setValue}
      />
      <CheckboxInput
        {...register("isSelfInterest")}
        {...getMeta(form, "isSelfInterest")}
        label="Клиент подтверждает, что действует в личных интересах и не имеет бенефициарных владельцев"
        watch={watch}
        onChange={setValue}
      />
      {productType === ProductType.PIF && (
        <CheckboxInput
          {...register("isRefusesNotifications")}
          {...getMeta(form, "isRefusesNotifications")}
          label="Клиент отказывается от получения уведомлений управляющей компании о намерении приобрести в состав его активов инвестиционные паи паевого инвестиционного фонда, находящего под управлением АО УК «Ингосстрах-Инвестиции»"
          watch={watch}
          onChange={setValue}
        />
      )}
      {productType === ProductType.IIS && (
        <div className="requirements-section__iis">
          <Switch
            id="hasAnotherIIS"
            label="Есть ИИС в другой брокерской/управляющей компании"
            labelPosition="left"
            value={hasAnotherIIS}
            onChange={(value) => setValue("hasAnotherIIS", value)}
          />
          {hasAnotherIIS && (
            <>
              <Input
                id="anotherIISOrganization"
                label="Наименование брокерской/управляющей компании"
                placeholder="Наименование брокерской/управляющей компании"
                {...register("anotherIISOrganization")}
                {...getMeta(form, "anotherIISOrganization")}
              />
              <Informer
                isOpen
                type="info-without-shadow"
                isDisplayIcon
                text="Клиенту необходимо закрыть ИИС в другой брокерской/управляющей компании в течение 30 дней для сохранения права получения налогового вычета."
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RequirementsSection;
