import * as yup from "yup";

import { IFundSelectForm } from "core/store/reducers/fund-select-reducer/types";
import { ProductType } from "core/types/entities/product-type";
import { formatNumberToRubCurrency } from "core/utils/number-formatters";
import { userStringToNumber } from "core/utils/number-utils";
import {
  BANK_OR_BIK_REGEX,
  CHECKING_ACCOUNT_REGEX,
  NO_EDGE_SPACES_REGEX,
} from "core/utils/regex-rules";

export const getFundSelectFormSchema = (productType: ProductType) => {
  const isNeedValidatePaymentData = productType === ProductType.PIF;
  return yup.object().shape({
    estimate: yup
      .string()
      .required("Укажите сумму инвестирования")
      .test(
        "isPositive",
        "Введите положительную сумму",
        (text) => userStringToNumber(text || "") > 0
      )
      .test((text, context) => {
        const minInvestBound = (context.parent as IFundSelectForm).selectedFund
          ?.minLimit;

        if (
          !minInvestBound ||
          userStringToNumber(text || "") >= minInvestBound
        ) {
          return true;
        }

        return new yup.ValidationError(
          `Минимальная сумма ${formatNumberToRubCurrency(minInvestBound)}`,
          false,
          "estimate"
        );
      })
      .test((text, context) => {
        const maxInvestBound = (context.parent as IFundSelectForm).selectedFund
          ?.maxLimit;

        if (
          !maxInvestBound ||
          userStringToNumber(text || "") <= maxInvestBound
        ) {
          return true;
        }

        return new yup.ValidationError(
          `Максимальная сумма ${formatNumberToRubCurrency(maxInvestBound)}`,
          false,
          "estimate"
        );
      }),
    selectedFund: yup.object().shape({
      shareId: yup.number().required("Выберите фонд"),
    }),
    paymentAccount: isNeedValidatePaymentData
      ? yup
          .string()
          .required("Укажите номер расчётного счёта")
          .matches(
            CHECKING_ACCOUNT_REGEX,
            "Номер расчётного счёта содержит 20 цифр"
          )
      : yup.string(),
    bankAccount: isNeedValidatePaymentData
      ? yup.object().shape({
          bankName: yup
            .string()
            .required("Укажите наименование банка или БИК")
            .matches(
              BANK_OR_BIK_REGEX,
              "Проверьте корректность введенных данных"
            )
            .matches(
              NO_EDGE_SPACES_REGEX,
              "Проверьте корректность введенных данных"
            )
            .max(200, "Превышена максимальная длина"),
        })
      : yup.object(),
  });
};
