import { useAppSelector } from "core/store/hooks";
import { paymentSelectors } from "core/store/reducers/payment-reducer";
import { personalDataSelectors } from "core/store/reducers/personal-data-reducer";
import { usePaymentPageInitialize } from "pages/payment-page/page-hooks";

export const usePaymentPageState = () => {
  usePaymentPageInitialize();

  const paymentInfo = useAppSelector(paymentSelectors.selectPaymentInfo);
  const { mobile } = useAppSelector(personalDataSelectors.selectPersonalData);
  const name = useAppSelector(personalDataSelectors.selectName);
  const fullName = useAppSelector(personalDataSelectors.selectFullName);

  return { paymentInfo, name, fullName, mobile };
};
