import { FC, useMemo } from "react";

import classNames from "classnames";
import { Informer, Svg, useToggle } from "ii-ui-kit";

import { ReactComponent as RequisitesIcon } from "assets/icons/requisites.svg";
import { ReactComponent as SbpIcon } from "assets/icons/sbp.svg";
import { SPB_MAX_INVEST_AMOUNT } from "const";
import { IPayerInfoModel } from "pages/payment-page/types";

import { RequisitesContainer } from "../requisites/requisites.container";
import SbpPayment from "../sbp-payment";

import "./index.scss";

export type PaymentWaysProps = {
  amountMoney: number;
  payerInfo: IPayerInfoModel;
};

const PaymentWays: FC<PaymentWaysProps> = ({ amountMoney, payerInfo }) => {
  const [isOpenRequisites, toggleIsOpenRequisites] = useToggle(false);
  const [isOpenSbp, toggleIsOpenSbp] = useToggle(false);

  const isSbpAvailable = useMemo(() => {
    return amountMoney <= SPB_MAX_INVEST_AMOUNT;
  }, [amountMoney]);

  return (
    <div className="payment-ways">
      <div className="payment-ways__title">Способ оплаты</div>
      <div className="payment-ways__content">
        <div
          onClick={toggleIsOpenSbp}
          className={classNames("payment-ways__way", {
            "payment-ways__way--opened": isOpenSbp,
          })}
        >
          <div className="payment-ways__way-name">
            <SbpIcon />
            Оплата системой быстрых платежей
          </div>
          <div className="payment-ways__way-chevron">
            <Svg name="chevron-right" />
          </div>
        </div>
        {isSbpAvailable ? (
          <>{isOpenSbp && <SbpPayment phoneNumber={payerInfo.phoneNumber} />}</>
        ) : (
          <Informer
            isOpen
            isDisplayIcon
            type="info"
            title="Превышен лимит"
            text="Сумма оплаты с использованием Системы быстрых платежей не должна превышать или быть равной 1 млн. рублей. Воспользуйтесь оплатой по реквизитам."
          />
        )}

        <div
          onClick={toggleIsOpenRequisites}
          className={classNames("payment-ways__way", {
            "payment-ways__way--opened": isOpenRequisites,
          })}
        >
          <div className="payment-ways__way-name">
            <RequisitesIcon /> Оплата по реквизитам
          </div>
          <div className="payment-ways__way-chevron">
            <Svg name="chevron-right" />
          </div>
        </div>
        {isOpenRequisites && (
          <RequisitesContainer
            amountMoney={amountMoney}
            payerInfo={payerInfo}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentWays;
