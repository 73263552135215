import { useExhaustiveEffect } from "ii-ui-kit";

import STEP_KEYS from "core/enums/steps";
import { useAppDispatch } from "core/store/hooks";
import { appActions } from "core/store/reducers/app-reducer";

export const useFundSelectPageInitialize = () => {
  const dispatch = useAppDispatch();

  useExhaustiveEffect(() => {
    dispatch(appActions.setCurrentStep(STEP_KEYS.FUND_SELECT));
  }, []);
};
