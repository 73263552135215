import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Reducers } from "core/enums/reducers";
import { appActions } from "core/store/reducers/app-reducer";
import { IFundSelectForm } from "core/store/reducers/fund-select-reducer/types";

const initialState: IFundSelectForm = {
  selectedFund: undefined,
  estimate: "",
  paymentAccount: "",
  bankAccount: {
    bankName: "",
    address: "",
    bik: "",
    inn: "",
    corrAccount: "",
  },
};

const fundSelectSlice = createSlice({
  name: Reducers.FundSelect,
  initialState,
  reducers: {
    setFundSelect: (state, { payload }: PayloadAction<IFundSelectForm>) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: {
    [appActions.clearStore.type]: () => {
      return initialState;
    },
  },
});

export const fundSelectActions = fundSelectSlice.actions;

export const fundSelectReducer = fundSelectSlice.reducer;
