import { FC } from "react";

import classNames from "classnames";
import { Svg } from "ii-ui-kit";
import "./index.scss";

type SelectControlProps = {
  value?: string;
  label: string;
  isOpen?: boolean;
  className?: string;
};

const SelectControl: FC<SelectControlProps> = ({
  value,
  label,
  isOpen,
  className,
}) => {
  const classes = classNames("select-control", className, {
    "select-control--open": isOpen,
    "select-control--has-value": Boolean(value),
  });

  return (
    <div className={classes}>
      {!value && <div className="select-control__placeholder">{label}</div>}
      {value && (
        <div className="select-control__content">
          <div className="select-control__label">{label}</div>
          <div className="select-control__value">{value}</div>
        </div>
      )}
      <div className="select-control__chevron">
        <Svg name="chevron-down" />
      </div>
    </div>
  );
};

export default SelectControl;
